@import 'src/styles/mixins';

.description {
  @include font-size-m;
  @include font-light;
  color: $textColorLight;
  margin-bottom: $gapSizeL;
}

.content-wrapper {
  @include desktop-only {
    flex: 1 1 auto;
    height: 30rem; // height here works as min-height (flex will stretch container as much as possible)
  }
}

.content {
  @include mobile-only {
    padding-left: 0;
    padding-right: 0;
  }

  @include desktop-only {
    @include pretty-scrollbar;
    overflow-y: auto;
  }

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  padding-bottom: $gapSizeL;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeL;
}
