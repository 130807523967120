@import 'src/styles/mixins';

@mixin mobile-paddings {
  padding-left: $gapSizeL;
  padding-right: $gapSizeL;
}

.container {
  @include desktop-only {
    width: $boxWidthSizeM;
  }

  @include mobile-only {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

.title {
  @include popup-title-with-back-arrow;
  @include mobile-only {
    margin-bottom: 0;
  }

  margin-bottom: 0;
}

.arrow-back {
  @include popup-arrow-back;

  &--disabled {
    cursor: default;

    &:hover {
      @include icon-fill-color($textColorLight);
    }
  }
}

.details {
  @include mobile-only {
    @include mobile-paddings;
  }

  @include desktop-only {
    min-height: 13.6rem;
  }

  display: flex;
  flex-wrap: wrap;
  margin-top: $gapSizeXL;
  padding-left: $gapSizeXXL;
  padding-right: $gapSizeXXL;
}

// TODO - fix amount value on bottom for mobile screens
.total-amount {
  @include desktop-only {
    align-items: center;
  }

  @include mobile-only {
    @include mobile-paddings;
    bottom: 0;
    box-sizing: border-box;
    height: $gapSizeXL * 5;
    margin-top: auto;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    width: 100%;
  }

  border-top: 1px solid $lineColor;
  display: flex;
  justify-content: space-between;
  margin-top: $gapSizeL;
  padding-bottom: $gapSizeL;
  padding-left: $gapSizeXXL;
  padding-right: $gapSizeXXL;
  padding-top: $gapSizeL;

  &__title {
    @include desktop-only {
      align-items: center;
    }

    @include font-size-l;
    color: $textColorLight;
    display: flex;
  }

  &__value {
    @include font-size-xl;
    color: $primaryColor;
  }
}

.actions {
  @include popup-footer;
  @include mobile-only {
    @include mobile-paddings;
    justify-content: center;
  }

  align-items: center;
}

.submit {
  @include mobile-only {
    width: 100%;
  }

  width: 20rem;
}
