@import 'src/styles/variables';
@import 'helpers';

// fill as default set to 2nd path, as most svgs have this path filled
@mixin icon-fill-color($color, $path: 2) {
  path {
    &:nth-of-type(#{$path}) {
      @include default-transition;
      fill: $color;
    }
  }
}
