@import 'src/styles/mixins';

.content {
  @include desktop-only {
    width: $boxWidthSizeXS;
  }

  display: flex;
  flex-direction: column;
  padding: $gapSizeXL;
}

.buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: $gapSizeM;
  padding-top: $gapSizeM;
}

.query {
  @include font-size-xl;
  margin-bottom: $gapSizeXL;
}

.decline {
  @include link;
  width: 50%;
}

.confirm {
  width: 50%;
}
