@import 'src/styles/mixins';

.agreement {
  @include font-size-m;
  color: $textColorLight;

  a {
    @include default-transition;
    color: $textColorLight;

    &:hover {
      color: $primaryColor;
    }
  }
}
