@import 'src/styles/mixins';

.loader {
  margin-top: $gapSizeXXL;
}

.title {
  @include popup-title
}

.container {
  @include popup-body;
  @include desktop-only {
    max-height: 50rem;
    max-width: $boxWidthSizeL;
  }

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
}

.product-info {
  align-items: center;
  border-bottom: 1px solid $lineColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: $gapSizeXL;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeL;
  text-align: center;
}

.avatar {
  @include size(6.4rem);
  background: $diskartechLogoSecondaryColor;
  font-size: 3.6rem;
  margin-bottom: $gapSizeS;
  text-align: center;
  text-transform: uppercase;
}

.error-bar-sticky-container {
  margin-bottom: $gapSizeXXL;
  position: sticky;
  top: 0;
  z-index: $zIndexPrioritized;
}
