@import 'src/styles/mixins';

.description {
  @include font-size-m;
  color: $textColorLight;
  margin-bottom: $gapSizeL;
}

.input {
  margin-bottom: $gapSizeM;
  margin-top: $gapSizeM;
  width: 100%;
}

.button {
  margin-top: $gapSizeL;
  width: 100%;
}

.option {
  align-items: flex-start;
  border: 1px solid $primaryColor;
  border-radius: $smallBorderRadius;
  display: flex;
  margin-top: $gapSizeL;
  padding: $gapSizeS;

  &__label {
    color: $textColorLight;
    display: flex;
    flex-direction: column;
    margin-left: $gapSizeS;
  }

  &__recipient {
    color: $textColor;
    margin-top: $gapSizeS;
  }

  &__radiobutton {
    padding: 0;
  }
}
