@import 'src/styles/mixins';

.container {
  @include desktop-only {
    max-width: $boxWidthSizeXS;
    padding-left: $gapSizeXL;
    padding-right: $gapSizeXL;
  }

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: $gapSizeL;
  padding-right: $gapSizeL;
}

.image {
  margin-bottom: $gapSizeS;
  margin-left: auto;
  margin-right: auto;
  margin-top: $gapSizeL;
  max-width: 100%;
  width: 26rem;
}

.title {
  @include font-size-xl;

  margin-bottom: $gapSizeS;
}

.description {
  @include font-size-m;
  @include font-light;

  color: $textColorLight;
  margin-bottom: $gapSizeM;
}

.link {
  @include link;
  margin-bottom: $gapSizeXL;
  margin-left: auto;
  margin-right: auto;
  margin-top: $gapSizeL;
}
