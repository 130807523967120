@import 'src/styles/mixins';

.category {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__name {
    @include font-size-m;
    padding-top: $gapSizeXS;
    text-align: center;
    white-space: normal;
  }

  &__icon {
    @include mobile-only {
      @include size($smallMobileTileSize);
    }

    @include default-transition;
    @include size($tileSize);
    border: 1px solid $lineColor;
    border-radius: $bigBorderRadius;
    box-sizing: border-box;
    cursor: pointer;
    padding: $gapSizeM;

    &:hover {
      background: $lightBackgroundColor;
    }
  }
}
