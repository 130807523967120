@import 'src/styles/mixins';

.amount {
  background: $primaryBackgroundColor;
  border: 1px solid $primaryColor;
  border-radius: $smallBorderRadius;
  box-sizing: border-box;
  padding-bottom: $gapSizeS;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeS;
  width: 100%;

  &__label {
    @include font-size-s;
    color: $textColorLight;
    margin-bottom: $gapSizeXS / 2;
  }

  &__value {
    @include font-size-xl;
    color: $primaryColor;
  }
}
