@import 'src/styles/mixins';

.main-container {
  max-width: $boxWidthSizeXS;
  padding-bottom: $gapSizeL;
  padding-left: $gapSizeL;
  padding-right: $gapSizeL;
  padding-top: $gapSizeL * 2;
}

.title {
  @include font-size-xxl;
  margin-bottom: $gapSizeL;
}

.welcome-text {
  @include font-size-m;
  @include font-light;
  color: $textColorLight;
}

.list-title {
  @include font-size-m;
}
