@import 'src/styles/mixins';

.footer {
  @include mobile-only {
    height: $mobileFooterHeight;
    padding-bottom: $mobileBottomMenuHeight;
  }

  @include default-transition;
  align-items: center;
  background: $primaryColor;
  color: $white;
  display: flex;
  height: $footerHeight;
  justify-content: flex-start;

  &--logout-user {
    background: $lightBackgroundColor;
    border-top: 1px solid $lineColor;
    color: $textColorLight;
  }
}

.container {
  @include mobile-only {
    display: flex;
    flex-direction: column-reverse;
  }

  @include wide-desktop-only {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include desktop-width;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;

  &--logout-user {
    display: flex;
    justify-content: center;
  }
}

.copyright {
  @include mobile-only {
    height: $mobileFooterHeight / 3;
    justify-content: center;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
  }

  @include small-mobile-only {
    @include font-size-xs;
  }

  @include font-size-s;
  align-items: center;
  display: flex;
}

.faq-and-legal {
  @include mobile-only {
    @include font-size-m;
    border-bottom: 1px solid $white;
    height: $mobileFooterHeight / 3;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
  }

  @include small-mobile-only {
    @include font-size-s;
  }

  @include wide-desktop-only {
    @include font-size-m;
  }

  @include font-size-s;
  align-items: center;
  display: flex;
  justify-content: center;

  &__link {
    @include mobile-only {
      text-align: center;
    }

    @include small-mobile-only {
      margin-right: $gapSizeS;
    }

    color: $white;
    margin-right: $gapSizeM;

    &:last-child {
      margin-right: 0;
    }
  }
}

.social-media {
  @include mobile-only {
    justify-content: center;
    margin-top: $gapSizeL;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
  }

  align-items: center;
  display: flex;
  justify-content: flex-end;

  &__link {
    margin-right: $gapSizeL;

    &:last-child {
      margin-right: 0;
    }
  }
}
