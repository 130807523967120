@import 'src/styles/mixins';

.box {
  @include box;
  @include desktop-only {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: $gapSizeXL;
    max-width: 100%;
    width: 68.8rem;
  }
}

.image-wrapper {
  @include desktop-only {
    width: 50%;
  }

  @include mobile-only {
    margin-bottom: $gapSizeL;
    margin-top: $gapSizeL;
  }

  align-items: flex-start;
  display: flex;
  justify-content: center;
  margin-top: $gapSizeXXL;
}

.content {
  @include desktop-only {
    padding-bottom: $gapSizeXL;
    padding-top: $gapSizeXL;
    width: 25.6rem;
  }
}

.title {
  @include font-size-xl;
}

.description {
  @include font-size-m;
  color: $textColorLight;
  margin-bottom: $gapSizeM;
}

.point {
  @include font-size-l;
  align-items: flex-end;
  display: flex;
  margin-bottom: $gapSizeS;

  &__label {
    margin-left: $gapSizeXS;
  }

  &--checked {
    @include icon-fill-color($primaryColor);
    color: $primaryColor;
  }
}

.button {
  @include mobile-only {
    margin-bottom: $gapSizeL;
  }

  margin-top: $gapSizeXL;
  width: 100%;
}
