@import 'src/styles/mixins';

.container {
  @include desktop-only {
    max-width: $boxWidthSizeXS;
    padding-left: $gapSizeL + $gapSizeXL;
    padding-right: $gapSizeL + $gapSizeXL;
  }

  padding-bottom: $gapSizeL;
  padding-left: $gapSizeL;
  padding-right: $gapSizeL;
  padding-top: $gapSizeXL * 2;
}

.loader {
  margin-bottom: $gapSizeL;
  padding-bottom: $gapSizeXS;
}
