@import 'src/styles/mixins';

.container {
  @include desktop-only {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.add-goal {
  @include font-size-l;
  @include mobile-only {
    @include font-size-m;
    margin-top: $gapSizeXL;
    max-width: 100%;
    text-align: center;
  }

  @include small-mobile-only {
    max-width: calc(100% - 2 * #{$gapSizeM});
  }

  @include default-transition;
  border: 2px solid $primaryColor;
  border-radius: $smallBorderRadius;
  box-sizing: border-box;
  color: $primaryColor;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 4.8rem;
  justify-content: center;
  margin-bottom: $gapSizeXL;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  width: 28.8rem;

  &:hover {
    background: $lightBackgroundColor;
  }
}
