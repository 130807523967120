@import 'src/styles/mixins';

.products {
  display: flex;
  flex-direction: column;
  padding: $gapSizeL;
}

.product {
  @include default-transition;
  align-items: center;
  border: 2px solid $lineColor;
  border-radius: $smallBorderRadius;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: $gapSizeM;
  padding: $gapSizeM;

  &:hover {
    background: $lightBackgroundColor;
  }
}

.avatar {
  @include size(4.8rem);
  background: $primaryColor;
  color: $white;
  display: flex;
  flex-direction: column;
  margin-right: $gapSizeM;
}

.currency {
  @include font-size-s;
}

.amount {
  @include font-size-xl;

  &--smaller-font {
    @include font-size-l;
  }

  &--smallest-font {
    @include font-size-m;
  }
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.title {
  @include font-bold;
}

.description {
  @include font-size-s;
}
