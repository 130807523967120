@import 'src/styles/mixins';

.icon-wrapper {
  @include desktop-only {
    max-width: 20rem;
  }

  @include font-size-m;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: $gapSizeM;
  text-align: center;

  img {
    padding-bottom: $gapSizeM;
    padding-top: $gapSizeM;
  }
}
