@import 'src/styles/mixins';

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: $gapSizeM;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeM;
}

.container {
  max-width: 28.8rem;
}

.image {
  display: flex;
  justify-content: center;
  margin: $gapSizeXL;
}

.header {
  @include mobile-only {
    @include font-size-xxl;
  }

  @include font-size-xl;

  align-self: center;
  display: flex;
  justify-content: center;
  margin-bottom: $gapSizeM;
  word-wrap: anywhere;
}

.text {
  @include font-size-m;
  @include font-light;

  color: $textColorLight;
  display: flex;
  justify-content: center;
  margin-bottom: $gapSizeL;
}

.add-goal {
  @include font-size-l;

  border-radius: $smallBorderRadius;
  display: flex;
  flex-direction: column;
  height: 4.8rem;
  justify-content: center;
  margin-bottom: $gapSizeM;
  width: 100%;
}

.abort {
  @include font-size-m;
  @include font-light;

  border-radius: $smallBorderRadius;
  color: $primaryColor;
  display: flex;
  flex-direction: column;
  height: 4.8rem;
  justify-content: center;
  margin-bottom: $gapSizeXL;
  text-decoration: underline;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
}
