@import 'src/styles/variables';

.field {
  margin-top: $gapSizeL;

  &:first-child {
    margin-top: $gapSizeM;
  }

  &:last-child {
    margin-bottom: $gapSizeM;
  }
}
