@import 'src/styles/mixins';

.dropdown {
  color: $textColor;
  display: flex;
  justify-content: flex-end;
  position: relative;

  &__modal {
    @include desktop-only {
      padding-bottom: $gapSizeXXS;
      padding-top: $gapSizeXXS;
    }

    @include default-box-shadow;
    @include default-transition;
    background: $white;
    border-radius: $bigBorderRadius;
    box-sizing: border-box;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 6rem;
    width: 36rem;
    z-index: $zIndexNegative;

    &--show {
      opacity: 1;
      z-index: $zIndexPrioritized;
    }
  }
}

.user-short-name {
  @include font-size-m;
  margin-left: $gapSizeXS;
  margin-right: 0.2rem;
}

.user-button {
  @include default-transition;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 100%;
  justify-content: flex-end;

  &:hover {
    @include icon-fill-color($primaryColor);
    color: $primaryColor;
  }
}
