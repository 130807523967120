@import 'src/styles/mixins';

.container {
  @include desktop-only {
    min-height: 40rem;
    min-width: $boxWidthSizeM;
  }

  display: flex;
  flex-direction: column;
}
