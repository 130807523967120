@import 'src/styles/mixins';

.container {
  @include small-mobile-only {
    @include font-size-m;
  }

  @include font-size-l;
  align-items: center;
  color: $textColorLight;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-left: $gapSizeS;
  padding-right: $gapSizeS;
  text-align: center;
}

.link {
  @include link;
  margin-top: $gapSizeXS;
}
