@import 'src/styles/mixins';

.main-container {
  @include desktop-only {
    background: $lightBackgroundColor;
  }

  width: $boxWidthSizeM;
}

.title {
  @include mobile-only {
    @include font-size-l;
  }

  @include font-size-xxl;
  @include popup-title;

  background: $white;
  position: relative;
}

.footer {
  @include popup-footer;

  position: relative;
}

.iframe-container {
  height: 50rem;
  margin-left: $gapSizeL;
  margin-right: $gapSizeL;

  &__iframe {
    border: 0;
  }
}
