@import 'src/styles/mixins';

.mobile-stepper {
  @include desktop-only {
    display: none;
  }

  background: none;
  padding: 0;

  &__dots {
    width: 100%;
  }

  &__dot {
    background: $primaryColor;
    border-radius: $bigBorderRadius;
    flex: 1;
    margin-left: 0;
    margin-right: 0;

    & + .mobile-stepper__dot {
      margin-left: 0.5rem;
    }

    &--active {
      & ~ .mobile-stepper__dot {
        background: $primaryBackgroundColor;
      }
    }
  }
}

.desktop-stepper {
  @include mobile-only {
    display: none;
  }

  border-radius: $bigBorderRadius;
}

.label {
  @include font-size-s;
  color: $stepperLightColor;

  &--active {
    @include font-bold;
    color: $primaryColor !important; // sass-lint:disable-line no-important
  }
}

.icon {
  fill: $stepperLightColor;

  &--active {
    fill: $primaryColor;

    circle {
      stroke: none;
    }
  }

  &__text {
    @include font-size-s;
  }
}
