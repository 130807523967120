@import 'src/styles/mixins';

.container {
  @include desktop-only {
    width: $boxWidthSizeXS;
  }

  @include mobile-only {
    padding-bottom: 2 * $gapSizeL;
    padding-left: $gapSizeL;
    padding-right: $gapSizeL;
    padding-top: $gapSizeXL;
  }

  @include small-mobile-only {
    padding-bottom: $gapSizeXL;
    padding-top: $gapSizeM;
  }

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: 2 * $gapSizeL;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeXL;
}

.title {
  @include small-mobile-only {
    @include font-size-l;
    margin-top: 0;
  }
  @include font-size-xl;
  @include font-bold;
  margin-top: $gapSizeL;

}

.description {
  @include small-mobile-only {
    @include font-size-m;
  }
  @include font-size-l;
  color: $textColorLight;
  margin-top: $gapSizeS;
}

.action {
  @include small-mobile-only {
    @include font-size-m;
  }
  @include font-size-l;
  margin-top: $gapSizeM;
}

.button {
  width: 100%;
}
