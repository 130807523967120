@import 'src/styles/mixins';

.tile {
  @include small-mobile-only {
    max-width: $smallMobileTileSize;
  }

  @include mobile-only {
    margin-right: 0;
  }

  align-items: center;
  color: $textColorLight;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $gapSizeL;
  margin-right: $gapSizeM;
  max-width: $tileSize;
  text-align: center;

  &:hover {
    .tile__icon-wrapper {
      background: $lightBackgroundColor;
    }
  }

  &__icon-wrapper {
    @include small-mobile-only {
      @include size($smallMobileTileSize);
    }

    @include default-transition;
    @include size($tileSize);
    align-items: center;
    border: 1px solid $lineColor;
    border-radius: $bigBorderRadius;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-bottom: $gapSizeXS;
  }
}
