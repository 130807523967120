@import 'src/styles/mixins';

.title {
  @include popup-title;
}

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.container {
  @include popup-body;
  align-items: center;
  flex-grow: 1;
  padding-left: $gapSizeXXL;
  padding-right: $gapSizeXXL;
  padding-top: $gapSizeXL;
}

.description {
  @include font-size-l;
  @include mobile-only {
    @include font-size-m;
  }

  margin-bottom: $gapSizeL;
  margin-top: $gapSizeL;
}

.actions {
  @include popup-footer
}

.submit {
  @include mobile-only {
    width: 100%;
  }
}

.full-width {
  width: 100%;
}
