@import 'src/styles/mixins';

.container {
  @include mobile-only {
    flex-direction: column;
    padding-left: $gapSizeL;
    padding-right: $gapSizeL;
    padding-top: $gapSizeL;
  }

  box-sizing: border-box;
  display: flex;
  max-width: 69rem;
}

.column {
  @include flex-column-centered-content;
  @include desktop-only {
    padding-bottom: 5.6rem;
    padding-left: $gapSizeXL;
    padding-right: $gapSizeXL;
    padding-top: 6.6rem;
    width: 50%;
  }

  padding-top: $gapSizeM;
  width: 100%;

  &--left {
    @include mobile-only {
      background: none;
      flex-direction: column;
    }

    background: $lightBackgroundColor;
  }
}

.withdraw-details {
  @include mobile-only {
    padding-top: $gapSizeXS;
  }

  border: 1px solid $lineColor;
  border-radius: $smallBorderRadius;
  height: 100%;
  padding: $gapSizeL;
}

.icon {
  @include size(4rem);
  margin-left: auto;
  margin-right: auto;
  margin-top: $gapSizeL;
}

.title {
  @include font-size-xl;
  margin-top: $gapSizeM;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  text-align: center;
}

.description {
  @include font-size-m;
  @include mobile-only {
    margin-top: $gapSizeM;
  }

  color: $textColorLight;
  margin-top: $gapSizeL;
  text-align: center;
}

.back-button {
  @include mobile-only {
    margin-top: $gapSizeM;
    width: 100%;
  }

  margin-left: auto;
  margin-right: auto;
  margin-top: $gapSizeXL;
  width: 80%;
}

.detail {
  width: 100%;

  &:first-child {
    border-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
}

.amount {
  @include font-size-l;
  color: $primaryColor;
  padding-left: 0.3rem;

  &--negative {
    color: $dangerColor;
  }
}

.only-desktop {
  @include flex-column-centered-content;
  @include only-on-desktop;
}

.only-mobile {
  @include flex-column-centered-content;
  @include only-on-mobile;
}
