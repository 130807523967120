// colors
$primaryColor: #37a8c4;
$primaryBackgroundColor: #cfeef3;
$textColor: #212121;
$textColorLight: #686868;
$lightBackgroundColor: #f5f8f8;
$fileUploadBackgroundColor: #f5f1dd;
$lineColor: #eaeaea;
$lineColorDarker: #bababa;

$stepperLightColor: #9e9e9e;
$dangerColor: #e34d4d;
$dangerBackgroundColor: #ffdad5;
$successColor: #22c36c;
$successBackgroundColor: #caf1dc;

$white: #fff;

$diskartechLogoSecondaryColor: #f9aa16;

// breakpoints in px unit
$breakpointSm: 360;
$breakpointMd: 720;
$breakpointLg: 1024;

// font + base size
$mainFontFamily: 'Nunito', 'Open Sans', sans-serif;
$baseFontSize: 10px;

$fontSizes: (
  xx-large: 2.4rem,
  x-large: 2rem,
  large: 1.6rem,
  medium: 1.4rem,
  small: 1.2rem,
  x-small: 1rem
);

$fontWeights: (
  light: 300, // should be synchronized with _fonts.scss
  normal: normal,
  bold: bold
);

// sizes
$gapSizeXXS: 0.4rem;
$gapSizeXS: 0.8rem;
$gapSizeS: 1.2rem;
$gapSizeM: 1.6rem;
$gapSizeL: 2.4rem;
$gapSizeXL: 3.2rem;
$gapSizeXXL: 7.2rem;

$desktopContainerWidth: 104rem;

$boxSidePadding: $gapSizeL;
$buttonSidePadding: 2rem;

$headerHeight: $gapSizeXXL;
$footerHeight: $headerHeight;
$mobileFooterHeight: 2 * $gapSizeXXL;
$mobileBottomMenuHeight: $headerHeight;
$dashboardServicesRowHeight: 18.2rem;

$bannerDesktopHeight: 20rem;
$bannerDesktopWidth: 33.6rem;

$transactionSuccessBackLinkButtonWidth: 23rem;

// tile sizes
$smallMobileTileSize: $gapSizeXXL;
$tileSize: 8.8rem;

// box widths
$boxWidthSizeXS: 36.8rem;
$boxWidthSizeS: 44.8rem;
$boxWidthSizeM: 51.2rem;
$boxWidthSizeL: 68rem;
$boxWidthSizeXL: 72rem;

// radius
$smallBorderRadius: 0.8rem;
$bigBorderRadius: 1.6rem;

// z-indexes
$zIndexNegative: -1;
$zIndexStandard: 1;
$zIndexPrioritized: 2;
