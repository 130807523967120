@import 'src/styles/mixins';

.box {
  @include box;
  @include mobile-only {
    margin-left: auto;
    margin-right: auto;
    padding: $gapSizeL;
  }

  @include small-mobile-only {
    padding: $gapSizeM;
  }

  @include desktop-only {
    padding: $gapSizeXL + $gapSizeM;
  }

  margin-left: auto;
  margin-right: auto;
  max-width: $boxWidthSizeS;
}
