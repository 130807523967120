@import 'src/styles/mixins';

.partners {
  @include mobile-only {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 0;

    & > * {
      // every 1st, 4th, 7th etc.
      &:nth-child(3n + 1) {
        justify-self: left;
      }

      // every 2nd, 5th, 8th etc.
      &:nth-child(3n + 2) {
        justify-self: center;
      }

      // every 3th, 6th, 9th etc.
      &:nth-child(3n + 3) {
        justify-self: right;
      }
    }
  }

  @include wide-desktop-only {
    margin-right: -$gapSizeM;
    padding-left: $gapSizeS;
  }

  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.loader {
  margin-bottom: $gapSizeXL;
  margin-top: $gapSizeL;
  padding-bottom: $gapSizeS;
}
