@import 'src/styles/mixins';

.products {
  @include mobile-only {
    grid-template-columns: repeat(auto-fit, minmax($smallMobileTileSize, 1fr));
  }

  display: grid;
  grid-gap: $gapSizeM;
  grid-template-columns: repeat(5, 1fr);
  padding: $gapSizeL;
}

.product {
  @include default-transition;
  @include size($tileSize);
  @include mobile-only {
    @include size($smallMobileTileSize);
  }
  align-items: center;
  border: 1px solid $lineColor;
  border-radius: $smallBorderRadius;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    background: $lightBackgroundColor;
  }
}

.currency {
  color: $textColorLight;
}

.amount {
  @include font-size-xxl;
  color: $primaryColor;
}
