@import 'src/styles/mixins';

.header {
  @include mobile-only {
    margin-bottom: 0;
    margin-top: $gapSizeL;
  }

  @include desktop-width;
  margin-bottom: $gapSizeL;
  margin-left: auto;
  margin-right: auto;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
}
