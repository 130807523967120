@import 'src/styles/mixins';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.referral-hero {
  width: 20rem;
}

.header {
  line-height: 3.2rem;
  margin-bottom: $gapSizeM;
  margin-top: $gapSizeL;
}

.description {
  display: flex;
  flex-direction: column;

}

.paragraph {
  @include font-size-m;
  color: $textColorLight;
  margin-bottom: $gapSizeL;

  &:first-child {
    margin-top: $gapSizeS;
  }
}
