@import 'src/styles/mixins';

.main-container {
  @include desktop-only {
    max-width: $boxWidthSizeXS;
  }

  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: $gapSizeXL;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeXXL;
}

.success-title {
  @include font-size-xl;
  margin-top: $gapSizeXL;
}

.success-message {
  @include font-light;
  color: $textColorLight;
  margin-bottom: $gapSizeL;
  margin-top: $gapSizeL;
}

.button {
  width: 100%;
}
