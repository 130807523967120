@import 'src/styles/mixins';

.terms {
  color: $textColorLight;

  p {
    @include font-size-s;
    margin-bottom: $gapSizeM;
  }

  h1 {
    @include font-size-l;
    color: $textColor;
    margin-bottom: $gapSizeS;
  }

  h2 {
    @include font-size-m;
  }

  a {
    color: $primaryColor;
  }
}
