@import 'src/styles/mixins';

.radio-group-header {
  margin-bottom: $gapSizeXS;
  margin-top: $gapSizeL;

  &:first-of-type {
    margin-top: 0;
  }
}
