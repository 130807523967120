@import 'src/styles/mixins';

.input {
  @include mobile-only {
    margin-bottom: $gapSizeL;
  }

  margin-bottom: $gapSizeXL;

  &--half {
    @include desktop-only {
      width: calc(50% - #{$gapSizeM / 2});
    }
  }
}

.row {
  @include desktop-only {
    flex-direction: row;
    justify-content: space-between;
  }

  display: flex;
  flex-direction: column;
}

.form-group-label {
  @include font-size-m;
  margin-bottom: $gapSizeL;
}

.checkbox {
  &:not(:last-child) {
    margin-bottom: $gapSizeL;
  }

  &__label {
    color: $textColorLight;
    font-size: map-get($fontSizes, medium) !important; // sass-lint:disable-line no-important
  }
}
