@import 'src/styles/mixins';

.container {
  @include empty-state-container;
  flex-direction: column;
  min-height: 4.8rem;
}

.link {
  text-decoration: underline;
}
