@import 'src/styles/mixins';

.intro-text {
  @include mobile-only {
    @include font-size-m;
  }

  @include font-size-l;
  @include font-light;

  color: $textColorLight;
  margin-bottom: $gapSizeM;
}

.empty-state {
  @include font-size-m;
  @include font-light;

  align-items: center;
  color: $textColorLight;
  display: flex;
  height: $gapSizeXXL;
  justify-content: center;
}
