@import 'src/styles/mixins';

.login {
  @include mobile-only {
    grid-template-areas:
    'form'
    'register';
    margin-left: 0;
  }

  border-radius: $smallBorderRadius;
  display: grid;
  grid-template-areas:
  'register'
  'form'
  '.';
  height: 100%;
  margin-left: 6rem;
}

.form-container {
  @include desktop-only {
    margin-top: $gapSizeXXL;
  }

  grid-area: form;
}

.form-wrapper {
  @include desktop-only {
    max-width: 34rem;
  }

  min-height: 30rem;
  position: relative;
}

.form {
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: $gapSizeXL;
  }
}

.loader {
  margin-top: $gapSizeL;
}

.register {
  @include small-mobile-only {
    display: flex;
    flex-direction: column;
  }

  @include mobile-only {
    margin-bottom: $gapSizeM;
    text-align: center;
  }

  grid-area: register;
  text-align: right;

  &__link {
    @include welcome-screen-top-link;
  }
}

.errors {
  @include welcome-screen-errors;
}

.error {
  @include welcome-screen-error;
}

.forgotten-password-link {
  @include welcome-screen-action-link;
}

.welcome {
  color: $textColorLight;
  margin-bottom: $gapSizeM;
}

.title {
  margin-bottom: $gapSizeXL;
}
