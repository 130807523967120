@import 'src/styles/mixins';

.MuiTab-labelIcon {
  @include default-transition;

  &:hover {
    color: $primaryColor;
  }

  .MuiTab-wrapper {
    > :first-child {
      margin-bottom: 0;
    }
  }
}

.MuiTab-root {
  svg {
    .home-svg-color-part {
      @include default-transition;
    }

    .account-svg-color-part,
    .history-svg-color-part,
    .goal-svg-color-part {
      @include default-transition;
    }
  }

  &:hover,
  &.Mui-selected {
    svg {
      .home-svg-color-part {
        stroke: $primaryColor;
      }

      .account-svg-color-part,
      .history-svg-color-part,
      .goal-svg-color-part {
        fill: $primaryColor;
      }
    }
  }
}
