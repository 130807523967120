@import 'src/styles/mixins';

// TODO make this styles default in OperationDetail
.detail {
  width: 100%;

  &:first-child {
    border-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
}

.icon {
  @include size(6.4rem);
  margin-bottom: $gapSizeXL;
}

.cancel {
  @include font-size-l;
  border-width: 2px;
  margin-top: $gapSizeXL;
  width: 100%;
}
