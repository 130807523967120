@import 'helpers';
@import 'src/styles/variables';

@mixin box {
  @include mobile-only {
    margin-left: $gapSizeM;
    margin-right: $gapSizeM;
    margin-top: $gapSizeL;
    padding: $gapSizeM;
    width: calc(100% - 2 * #{$gapSizeM});
  }

  @include small-mobile-only {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  @include default-box-shadow;
  background: $white;
  border-radius: $bigBorderRadius;
  box-sizing: border-box;
  padding: $boxSidePadding;
}

@mixin desktop-width {
  @include desktop-only {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
    width: calc(#{$desktopContainerWidth} + 2 * #{$gapSizeM});
  }

  box-sizing: border-box;
  width: 100%;
}

@mixin empty-state-container {
  @include mobile-only {
    margin-bottom: $gapSizeXL;
    margin-top: $gapSizeXL;
  }

  @include font-light;
  @include font-size-m;
  align-items: center;
  color: $textColorLight;
  display: flex;
  justify-content: center;
  margin-top: 4rem;
  text-align: center;
}

@mixin flex-column-centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
