@import 'src/styles/mixins';

.container {
  @include desktop-only {
    max-width: 100%;
    width: $boxWidthSizeM;
  }
}

.content {
  @include desktop-only {
    width: 34rem;
  }

  @include mobile-only {
    margin-left: $gapSizeM;
    margin-right: $gapSizeM;
    margin-top: 2.8rem;
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 6.4rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6.4rem;

  &--no-margins {
    @include desktop-only {
      width: 100%;
    }

    @include mobile-only {
      margin-left: 0;
      margin-right: 0;
    }

    margin-bottom: 0;
    margin-top: 0;
  }
}

.header {
  @include mobile-only {
    margin-bottom: $gapSizeL;
    padding-right: $gapSizeXL;
  }

  @include font-size-xl;
  margin-bottom: $gapSizeM;

  &--bigger-font {
    @include desktop-only {
      @include font-size-xxl;
      margin-bottom: $gapSizeL;
    }
  }
}

.description {
  @include font-size-m;
  color: $textColorLight;

  &__phone {
    @include font-bold;
  }
}

.input-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: $gapSizeL;
  margin-top: $gapSizeL;
}

.input {
  @include mobile-only {
    min-height: 5.6rem;
    min-width: 4rem;
  }

  @include small-mobile-only {
    min-height: 4.6rem;
    min-width: 3.2rem;
  }

  @include default-transition;
  border: 2px solid $lineColor;
  border-radius: $smallBorderRadius;
  box-sizing: border-box;
  min-height: 6.4rem;
  min-width: 4.6rem;

  &--error {
    border: 2px solid $dangerColor;
    color: $dangerColor;
    outline: none;
  }

  &:focus {
    border: 2px solid $primaryColor;
    outline: none;
  }
}

.info {
  display: flex;
  justify-content: center;
  margin-bottom: $gapSizeXL;
}

.error-text {
  color: $dangerColor;
}

.resend-link {
  @include link;

  &--disabled {
    color: $textColorLight;
    cursor: not-allowed;
    pointer-events: none;
    text-decoration: none;
  }
}

.submit {
  width: 100%;
}
