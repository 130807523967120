@import 'src/styles/mixins';

.footer {
  @include small-mobile-only {
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
  }

  @include mobile-only {
    align-items: center;
    background: $white;
    border-top-left-radius: $bigBorderRadius;
    border-top-right-radius: $bigBorderRadius;
    bottom: 0;
    box-sizing: border-box;
    height: $mobileBottomMenuHeight;
    justify-content: center;
    left: 0;
    padding-bottom: 0;
    padding-top: 0;
    position: fixed;
    width: 100%;
    z-index: $zIndexPrioritized;
  }

  @include desktop-only {
    box-shadow: 0 -0.1rem 1.3rem -0.5rem $lineColor;
  }

  border-top: 1px solid $lineColor;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: $gapSizeL;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeM;

  &--two-buttons {
    @include mobile-only {
      height: 16.2rem;
    }
  }
}

.button {
  @include mobile-only {
    width: 100%;
  }

  &--back {
    @include mobile-only {
      margin-bottom: $gapSizeL;
    }
  }

  &--layout-with-bottom-text {
    @include mobile-only {
      margin-bottom: $gapSizeS;
    }
  }
}

.buttons {
  @include mobile-only {
    flex-direction: column-reverse;
    width: 100%;
  }

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.bottom-text {
  @include mobile-only {
    margin-bottom: $gapSizeXS;
    margin-top: $gapSizeS;
  }

  @include font-size-s;
  color: $textColorLight;
  margin-top: $gapSizeM;
  text-align: center;
}
