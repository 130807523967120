@import 'src/styles/mixins';

.title {
  @include font-size-l;
}

.option {
  margin-bottom: $gapSizeL;
  margin-top: $gapSizeL;
}

.container {
  @include mobile-only {
    @include font-size-m;
  }

  @include default-transition;
  @include font-size-l;
  align-items: center;
  border: 2px solid $lineColor;
  border-radius: $smallBorderRadius;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: $gapSizeL;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeL;

  &:hover {
    background: $lightBackgroundColor;
  }
}
