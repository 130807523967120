@import 'src/styles/mixins';

.close-button {
  @include mobile-only {
    right: 0;
  }

  position: absolute;
  right: $gapSizeM;
  top: $gapSizeM;
  z-index: $zIndexPrioritized;
}

.loader {
  padding: $gapSizeS;
  position: relative;
}
