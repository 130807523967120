@import 'src/styles/mixins';

.form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding-top: $gapSizeM;
}

.actions {
  @include popup-footer;
  margin-top: $gapSizeL;
}

.submit {
  @include mobile-only {
    width: 100%;
  }
}

.fields-container {
  @include mobile-only {
    padding-bottom: $mobileBottomMenuHeight + $gapSizeL;
    padding-left: $gapSizeXS;
    padding-right: $gapSizeXS;
  }

  display: flex;
  flex-direction: column;
  padding-left: $gapSizeL;
  padding-right: $gapSizeL;
}
