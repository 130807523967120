@import 'src/styles/mixins';

.row {
  @include default-transition;
  align-items: center;
  border-radius: $smallBorderRadius;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: $gapSizeL;
  /* negative margins to make room for paddings needed for hover styles */
  margin-left: -$gapSizeS;
  margin-right: -$gapSizeS;
  margin-top: $gapSizeL;
  padding-left: $gapSizeS;
  padding-right: $gapSizeS;

  &:hover {
    background: $lightBackgroundColor;
  }

  &--bordered {
    border: 2px solid $lineColor;
    border-radius: $smallBorderRadius;
    margin-bottom: $gapSizeXL;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding: $gapSizeM;

    .short-details {
      padding-right: 2rem;
    }
  }
}

.detail {
  line-height: 1.6rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;

  &--transaction-type {
    @include font-size-s;
    @include text-overflow-ellipsis;
    color: $textColorLight;
    max-width: 100%;
  }

  &--date {
    @include font-size-s;
    color: $textColorLight;
    display: flex;
    flex-direction: row-reverse;
  }
}

.short-details {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__column {
    max-width: 50%;
  }
}

.deposit-partner {
  @include font-size-m;
  line-height: 2rem;
}

.amount {
  @include font-size-m;
  color: $primaryColor;
  line-height: 2rem;
  text-align: right;

  &--negative {
    color: $dangerColor;
  }
}
