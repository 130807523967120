@import 'src/styles/mixins';

.title {
  @include popup-title;
}

.form {
  @include mobile-only {
    padding-bottom: $mobileBottomMenuHeight;
  }

  @include desktop-only {
    min-width: $boxWidthSizeXS;
  }

  display: flex;
  flex-direction: column;
}

.input-container {
  @include popup-body;
  margin-bottom: $gapSizeXL;
  padding-bottom: $gapSizeXL;
  padding-top: 0;
}

.actions {
  @include popup-footer;
}

.submit {
  @include mobile-only {
    width: 100%;
  }
}

.icon-wrapper {
  @include mobile-only {
    border-bottom: 1px solid $lineColor;
    margin-bottom: $gapSizeM;
    margin-top: $gapSizeM;
  }

  @include font-size-l;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $gapSizeXL;
  margin-top: $gapSizeXL;
  padding-bottom: $gapSizeM;

  img {
    padding-bottom: $gapSizeM;
    padding-top: $gapSizeM;
  }
}
