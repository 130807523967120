@import 'src/styles/mixins';

.main-container {
  @include desktop-only {
    max-width: $boxWidthSizeM;
  }
}

.heading {
  @include mobile-only {
    @include font-size-l;
  }
  @include font-size-xxl;
  @include popup-title;

  background: $white;
  position: relative;
}

.message-title {
  @include mobile-only {
    margin-left: $gapSizeL;
    margin-right: $gapSizeL;
  }

  @include font-size-l;

  margin-left: $gapSizeXXL;
  margin-right: $gapSizeXXL;
  margin-top: $gapSizeL;
}

.message-text {
  @include mobile-only {
    display: none;
  }

  @include font-size-m;
  @include font-light;

  color: $textColorLight;
  margin-bottom: $gapSizeM;
  margin-left: $gapSizeXXL;
  margin-right: $gapSizeXXL;
  margin-top: $gapSizeS;
}

.input-container {
  @include mobile-only {
    margin-left: $gapSizeL;
    margin-right: $gapSizeL;
    margin-top: $gapSizeL;
  }

  margin-bottom: $gapSizeXXL;
  margin-left: $gapSizeXXL;
  margin-right: $gapSizeXXL;
  margin-top: $gapSizeM;

  &__input {
    width: 100%;
  }
}

.footer {
  @include popup-footer;

  position: relative;
}
