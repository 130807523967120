@import 'src/styles/mixins';

$serviceIconDesktopWidth: 8rem;

.box {
  @include mobile-only {
    background: none;
    box-shadow: none;
    padding: 0;
  }

  @include small-mobile-only {
    @include default-box-shadow;
    background: $white;
    padding: $gapSizeM;
  }
}

.container {
  @include desktop-only {
    height: $dashboardServicesRowHeight;
  }
}

.header {
  @include mobile-only {
    display: none;
  }

  color: $textColor;
  margin-bottom: $gapSizeM;
}

.services {
  @include mobile-only {
    align-items: flex-start;
  }

  display: flex;
  justify-content: space-between;
}

.service {
  @include mobile-only {
    @include font-size-xs;
    color: $textColorLight;
    width: 6.4rem;
  }

  @include small-mobile-only {
    width: 4.8rem;
  }

  @include desktop-only {
    @include size(14.4rem);
    justify-content: flex-start;
    margin-top: $gapSizeXS;
    max-width: calc(25% - #{$gapSizeM});
  }

  @include wide-desktop-only {
    @include font-size-m;
    padding-left: $gapSizeXL;
    padding-right: $gapSizeXL;
  }

  @include default-transition;
  @include font-size-s;
  align-items: center;
  color: $textColor;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  &:hover {
    color: $primaryColor;
  }
}

.icon {
  @include mobile-only {
    @include size(4.8rem)
  }

  @include wide-desktop-only {
    @include size($serviceIconDesktopWidth);
  }

  @include size(6rem);
}

.icon-wrapper {
  @include mobile-only {
    @include default-box-shadow;
    align-items: center;
    background: $white;
    border-radius: $bigBorderRadius;
    display: flex;
    justify-content: center;
  }

  @include small-mobile-only {
    box-shadow: none;
  }

  @include wide-desktop-only {
    @include size($serviceIconDesktopWidth);
    margin-bottom: $gapSizeM;
  }

  @include size(6.4rem);
  margin-bottom: 0.6rem;
}

.label {
  max-width: $serviceIconDesktopWidth;
}
