@import 'src/styles/mixins';

.container {
  align-items: center;
  background-image: url('../../assets/images/not-found-page-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.box {
  @include box;
  @include mobile-only {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: $gapSizeL;
    padding-left: $gapSizeL;
    padding-right: $gapSizeL;
    padding-top: 0;
  }

  @include small-mobile-only {
    padding-bottom: $gapSizeM;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
    padding-top: 0;
  }

  @include desktop-only {
    padding-left: $gapSizeXL + $gapSizeM;
    padding-right: $gapSizeXL + $gapSizeM;
    padding-top: 0;
    width: $boxWidthSizeL;
  }

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.hero {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.logo {
  @include desktop-only {
    margin-bottom: $gapSizeXL + $gapSizeXL;
    margin-top: $gapSizeXL + $gapSizeXL;
    width: 16rem;
  }

  margin-top: $gapSizeM;
  max-width: 100%;
  width: 12rem;
}

.heading {
  @include desktop-only {
    font-size: 3.2rem;
    text-align: center;
  }

  margin-top: $gapSizeXL;
}

.description {
  @include desktop-only {
    text-align: center;
  }

  @include font-light;
  color: $textColorLight;
  margin-bottom: $gapSizeL;
}

.button {
  @include desktop-only {
    @include font-size-l;
  }

  margin-bottom: $gapSizeM;
  width: 100%;
}
