@import 'src/styles/mixins';

.user-info-wrapper {
  @include mobile-only {
    padding-bottom: $gapSizeM;
    padding-left: 0;
    padding-right: 0;
    padding-top: $gapSizeXS;
  }

  align-items: center;
  border-bottom: 1px solid $lineColor;
  display: flex;
  padding-bottom: $gapSizeS + $gapSizeXXS;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeS;

  img {
    width: 100%;
  }
}

.user-info {
  @include small-mobile-only {
    @include font-size-m;
  }

  @include font-size-l;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 4rem;
  justify-content: center;

  &__name {
    margin-bottom: auto;
  }

  &__account {
    @include font-size-s;
    color: $textColorLight;
  }
}

.icon {
  @include size(4rem);
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: $gapSizeXS;
}

.option {
  @include mobile-only {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: $gapSizeM;
  }

  @include default-transition;
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-bottom: $gapSizeXS;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeXS;

  &:hover {
    @include icon-fill-color($primaryColor);
    color: $primaryColor;
  }

  &:last-of-type {
    @include mobile-only {
      margin-top: $gapSizeM;
      padding-bottom: $gapSizeXS;
      padding-top: $gapSizeM;
    }

    border-top: 1px solid $lineColor;
    padding-bottom: $gapSizeS;
    padding-top: $gapSizeS;
  }

  &__label {
    @include font-size-m;
    font-weight: map-get($fontWeights, normal);
  }
}
