@import 'src/styles/mixins';

.main-container {
  @include mobile-only {
    grid-template-areas:
    'intro-header'
    'intro-text'
    'referral-code-container'
    'information-link';
  }

  @include desktop-only {
    grid-column-gap: $gapSizeM;
    grid-template-areas:
    'intro-header referral-code-container'
    'intro-text referral-code-container'
    'information-link referral-code-container';
    grid-template-columns: auto 32.8rem;
  }

  display: grid;
}

.intro-header {
  @include font-size-xl;

  grid-area: intro-header;
  margin-bottom: $gapSizeM;
}

.intro-text {
  @include font-size-m;
  @include font-light;

  color: $textColorLight;
  grid-area: intro-text;
  margin-bottom: $gapSizeL;
}

.information-link {
  @include desktop-only {
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
  }
  @include mobile-only {
    margin-bottom: $gapSizeL;
    margin-top: $gapSizeXL;
  }
  @include link;

  grid-area: information-link;
  text-align: center;
}

.referral-code-container {
  border: 1px solid $lineColor;
  border-radius: $bigBorderRadius;
  grid-area: referral-code-container;
}

.code-row {
  align-items: center;
  border-bottom: 1px solid $lineColor;
  display: flex;
  justify-content: space-between;
  padding-bottom: $gapSizeM;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeS;

  &__copy {
    @include font-size-s;

    border-radius: $bigBorderRadius !important; // sass-lint:disable-line no-important
    height: $gapSizeL;
    min-height: $gapSizeL !important; // sass-lint:disable-line no-important
    min-width: $gapSizeM * 5 !important; // sass-lint:disable-line no-important
    padding-left: $gapSizeXS;
    padding-right: $gapSizeXS;
    width: $gapSizeM * 5;
  }
}

.code-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__code {
    @include font-size-xl;

    color: $primaryColor;
  }

  &__code-label {
    @include font-size-s;

    color: $textColorLight;
  }
}

.progress {
  display: flex;
  flex-direction: column;
  padding-bottom: $gapSizeM;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeM;
}

.progress-container {
  align-items: center;
  display: flex;
  grid-area: progress;
  justify-content: center;
  margin-bottom: $gapSizeM;

  &__info {
    @include font-size-xs;

    color: $textColorLight;
    position: absolute;
    z-index: $zIndexPrioritized;
  }

  &__progress {
    height: $gapSizeL;
  }
}

.progress-info-container {
  @include font-size-s;

  color: $textColorLight;
  display: flex;
  justify-content: space-between;
}
