@import 'src/styles/mixins';

.tabs {
  @include mobile-only {
    @include default-box-shadow;
    background: $white;
    border-top-left-radius: $bigBorderRadius;
    border-top-right-radius: $bigBorderRadius;
    bottom: 0;
    height: $mobileBottomMenuHeight;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: $zIndexPrioritized;
  }

  display: flex;
  justify-content: center;

  &__indicator {
    @include mobile-only {
      display: none;
    }
  }
}

.tab {
  @include mobile-only {
    @include font-size-xs;
  }

  flex: 1;
  min-width: auto;

  &--account {
    @include desktop-only {
      display: none;
    }
  }
}
