@import 'src/styles/mixins';

.container {
  @include desktop-only {
    max-width: $boxWidthSizeXS;
    padding-left: $gapSizeL;
    padding-right: $gapSizeL;
  }

  display: flex;
  flex-direction: column;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
}

.image {
  margin-bottom: $gapSizeM;
  margin-left: auto;
  margin-right: auto;
  margin-top: $gapSizeL * 2;
  max-width: 100%;
}

.list {
  margin-bottom: $gapSizeL;

  &__title {
    @include font-size-xl;
  }

  &__item {
    @include font-size-l;
    @include font-light;
  }
}

.button {
  margin-bottom: $gapSizeXL;
}
