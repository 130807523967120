@import 'src/styles/variables';
@import 'icons';
@import 'helpers';

@mixin popup-title {
  @include mobile-only {
    @include font-size-l;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
  }

  @include font-size-xxl;
  border-bottom: 1px solid $lineColor;
  box-shadow: 0 0 0.8rem 0.2rem $lineColor;
  padding-bottom: $gapSizeL;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeL;
}

@mixin popup-body {
  @include mobile-only {
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
  }

  display: flex;
  flex-direction: column;
  padding-bottom: $gapSizeM;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeM;
}

@mixin popup-footer {
  @include mobile-only {
    align-items: center;
    border-top-left-radius: $bigBorderRadius;
    border-top-right-radius: $bigBorderRadius;
    bottom: 0;
    box-sizing: border-box;
    height: $mobileBottomMenuHeight;
    justify-content: center;
    left: 0;
    padding-bottom: 0;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
    padding-top: 0;
    position: fixed;
    width: 100%;
  }

  @include default-box-shadow;
  background: $white;
  border-top: 1px solid $lineColor;
  display: flex;
  justify-content: flex-end;
  padding-bottom: $gapSizeM;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeM;
  z-index: $zIndexPrioritized;
}

@mixin popup-success-footer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: $gapSizeXL;
}

@mixin operation-details-popup-container {
  @include mobile-only {
    margin-left: $gapSizeL;
    margin-right: $gapSizeL;
  }

  display: flex;
  flex-direction: column;
  margin-bottom: $gapSizeXL;
  margin-top: $gapSizeXL;
  padding-bottom: $gapSizeM;
}

@mixin popup-title-with-back-arrow {
  @include popup-title;
  @include mobile-only {
    margin-bottom: $gapSizeM;
    padding-left: $gapSizeL;
  }

  align-items: center;
  display: flex;
  padding-left: $gapSizeXXL;
}

@mixin popup-arrow-back {
  @include icon-fill-color($textColorLight);
  @include mobile-only {
    margin-right: $gapSizeM;
    position: static;
  }

  align-items: center;
  cursor: pointer;
  display: flex;
  left: $gapSizeXL;
  position: absolute;

  &:hover {
    @include icon-fill-color($primaryColor);
  }
}
