@import 'src/styles/mixins';

.container {
  @include operation-details-popup-container;
  margin-left: 7.2rem;
  margin-right: 7.2rem;
}

.header {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__title {
    @include font-size-xl;
    color: $textColor;
    padding-bottom: 0.5rem;
  }
}

.description {
  @include font-size-m;
  color: $textColorLight;
  padding-bottom: $gapSizeL;
}

.icon-container {
  @include size(4.5rem);
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: $gapSizeS;
}

.icon {
  @include size(4.5rem);
}

.details {
  border: 2px solid $lineColor;
  border-radius: $smallBorderRadius;
  display: flex;
  flex-flow: wrap;
  max-width: 40rem;
  padding: $gapSizeL;
}

.amount {
  @include font-size-l;
  color: $primaryColor;

  &--negative {
    color: $dangerColor;
  }
}
