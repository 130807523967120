@import 'src/styles/mixins';

.title {
  @include popup-title;
}

.container {
  @include popup-body;
  flex-direction: row;
  height: 4.8rem;
  padding-bottom: $gapSizeXL;
  padding-top: $gapSizeXL;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.label {
  @include font-size-s;
}

.mobile {
  @include font-bold;
  padding-top: $gapSizeXXS;
}

.logo {
  align-items: center;
  display: flex;
  margin-right: $gapSizeM;
  width: 5.6rem;

  img {
    max-width: 100%;
  }
}

.tabs {
  border-bottom: 1px solid $lineColor;
  border-top: 1px solid $lineColor;
  box-shadow: 0 0 0.8rem 0.2rem $lineColor;
  display: flex;
  justify-content: center;
}

.tab {
  flex: 1;
}

.tab-content {
  @include mobile-only {
    width: auto;
  }

  height: 35rem;
  width: 56rem;
}
