@import 'src/styles/mixins';

.snackbar-wrapper {
  position: relative;
}

.snackbar {
  @include small-mobile-only {
    left: 0;
  }

  position: absolute;
  top: 0;
  width: 100%;
  z-index: $zIndexPrioritized;

  &--main {
    top: $headerHeight;
  }

  &__message {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
    padding-left: $gapSizeL;
    padding-right: $gapSizeL;
    padding-top: 0;
    text-align: center;
    width: 100%;
    word-break: break-word;
  }

  &__content {
    @include font-size-s;
    background: $successBackgroundColor;
    border-bottom-left-radius: $smallBorderRadius;
    border-bottom-right-radius: $smallBorderRadius;
    box-shadow: 0 0 0.8rem 0.2rem $lineColor;
    color: $successColor;
    flex-grow: 1;
    flex-wrap: nowrap;
    padding-bottom: $gapSizeS;
    padding-top: $gapSizeS;

    &--error {
      background: $dangerBackgroundColor;
      color: $dangerColor;
    }
  }
}
