@import 'src/styles/mixins';

.row {
  @include default-transition;
  align-items: center;
  border: 2px solid $lineColor;
  border-radius: $smallBorderRadius;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: $gapSizeXL;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: $gapSizeM;

  &:hover {
    background: $lightBackgroundColor;
  }
}

.detail {
  @include text-overflow-ellipsis;
  line-height: 1.6rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;

  &__label {
    @include font-size-s;
    color: $textColorLight;
    margin-bottom: $gapSizeXS;
  }
}

.amount {
  @include font-size-m;
  color: $primaryColor;
  margin-left: auto;
  margin-right: $gapSizeM;
  text-align: right;

  &--negative {
    color: $dangerColor;
  }
}

.arrow-right-icon {
  @include mobile-only {
    display: none;
  }
}
