@import 'src/styles/mixins';

.container {
  @include desktop-only {
    background: linear-gradient(90deg, $lightBackgroundColor 0 50%,  $white 50%);
    display: grid;
    grid-template-areas:
    'arrow reminders'
    'hero reminders'
    'submit reminders';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 7.2rem auto 2*$footerHeight;
    max-width: 100%;
    width: $boxWidthSizeXL;
  }

  @include mobile-only {
    padding-bottom: $gapSizeL;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
    padding-top: $gapSizeL;
  }
}

.arrow-back {
  @include mobile-only {
    left: $gapSizeM;
    top: $gapSizeL;
  }

  @include popup-arrow-back;
  grid-area: arrow;
  left: $gapSizeXL;
  top: $gapSizeXL;
}

.hero {
  @include mobile-only {
    flex-direction: column-reverse;
  }

  display: flex;
  flex-direction: column;
  grid-area: hero;

  &__title {
    @include mobile-only {
      margin-bottom: 0;
      margin-top: $gapSizeM;
    }

    @include small-mobile-only {
      @include font-size-l;
    }

    @include desktop-only {
      margin-left: $gapSizeXL;
      margin-right: $gapSizeXL;
    }

    @include font-size-xl;
  }

  &__image {
    @include mobile-only {
      margin-bottom: $gapSizeS;
      margin-top: $gapSizeL;
    }

    margin-bottom: $gapSizeL;
    margin-left: auto;
    margin-right: auto;
    margin-top: $gapSizeXL;
    max-width: 100%;
  }
}

.submit {
  @include mobile-only {
    margin-top: $gapSizeXL;
  }

  @include desktop-only {
    grid-area: submit;
    margin-left: $gapSizeXL;
    margin-right: $gapSizeXL;
  }

  margin-bottom: $gapSizeXL;

  &__button {
    width: 100%;
  }
}

.reminders {
  @include desktop-only {
    grid-area: reminders;
    padding-bottom: $gapSizeXL;
    padding-left: $gapSizeM;
    padding-right: $gapSizeL;
    padding-top: $gapSizeS;
  }
}

.list-title {
  @include desktop-only {
    margin-top: $gapSizeL;
  }

  @include font-size-m;
  margin-bottom: -$gapSizeS;
}

.step-points {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $gapSizeXXS;
  margin-top: $gapSizeXS;

  &__point {
    @include small-mobile-only {
      @include font-size-s;
      padding-right: $gapSizeXXS;
    }

    @include font-size-m;
    align-items: center;
    box-sizing: border-box;
    color: $textColor;
    display: flex;
    margin-bottom: $gapSizeXS;
    width: 50%;

    &:before {
      @include small-mobile-only {
        margin-left: 0;
        margin-right: 0.6rem;
      }

      @include size(1.2rem);
      background-image: url('../../../assets/images/icons/status-icons/status-active.svg');
      content: '';
      display: block;
      margin-left: $gapSizeXS;
      margin-right: $gapSizeXS;
    }
  }
}

