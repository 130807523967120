@import 'src/styles/mixins';

.row {
  border-bottom: 1px solid $lineColor;
  cursor: pointer;
  display: flex;
  margin-bottom: $gapSizeXL;
  margin-left: -$boxSidePadding;
  margin-right: -$boxSidePadding;
  margin-top: -$boxSidePadding;
  padding-bottom: $gapSizeXL;
  padding-left: $boxSidePadding;
  padding-right: $boxSidePadding;
  padding-top: $boxSidePadding;
  position: relative;

  &::before {
    @include default-transition;
    border-radius: $bigBorderRadius;
    content: '';
    display: block;
    height: calc(100% - #{$gapSizeXL});
    left: $gapSizeM;
    position: absolute;
    top: $gapSizeM;
    width: calc(100% - #{2 * $gapSizeM});
    z-index: $zIndexStandard;
  }

  &:hover {
    &::before {
      background: $lightBackgroundColor;
    }
  }

  & + .row {
    margin-top: -$gapSizeXL;
  }
}

.option {
  align-items: center;
  border-top-left-radius: $bigBorderRadius;
  border-top-right-radius: $bigBorderRadius;
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 100%;
  z-index: $zIndexStandard;

  &__icon {
    @include size(4.8rem);
    padding-right: $gapSizeM;
  }
}

.details {
  display: flex;
  flex-direction: column;
}

.description {
  @include font-size-s;
  color: $textColorLight;
  margin-top: 0.4rem;
}

.arrow-right-icon {
  @include mobile-only {
    display: none;
  }

  position: relative;
  z-index: $zIndexStandard;
}
