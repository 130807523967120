@import 'src/styles/mixins';

.balance {
  display: flex;
  justify-content: space-between;

  &__header {
    @include mobile-only {
      @include font-size-s;
    }

    @include font-size-l;
    color: $textColorLight;
  }

  &__header-value {
    @include mobile-only {
      @include font-size-l;
      line-height: 2.2rem;
    }

    line-height: 3.2rem;
    margin-bottom: 0;
  }
}

.interest {
  @include mobile-only {
    @include font-size-s;
  }

  @include font-size-m;
  align-items: flex-end;
  color: $primaryColor;
  display: flex;
  flex-direction: column;
  text-align: right;

  &__earned {
    margin-bottom: 0.4rem;
  }
}

.actions {
  @include mobile-only {
    padding-top: $gapSizeM;
  }

  align-items: flex-start;
  border-top: 1px solid $lineColor;
  display: flex;
  justify-content: space-between;
  margin-top: $gapSizeL;
  padding-top: $gapSizeL;
}

.action-button {
  @include mobile-only {
    @include font-size-xs;
    height: auto;
    width: auto;
  }

  @include wide-desktop-only {
    @include font-size-m;
    border: 1px solid $primaryColor;
    border-radius: $smallBorderRadius;
    box-sizing: border-box;
    color: $primaryColor;
    cursor: pointer;
    flex-direction: row;
    justify-content: flex-start;
    max-width: calc(25% - #{$gapSizeM});
    text-align: center;

    &:hover {
      background: $lightBackgroundColor;
      color: $primaryColor;
    }
  }

  @include default-transition;
  @include font-size-s;
  align-items: center;
  border: 0;
  color: $textColorLight;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 4.8rem;
  max-width: calc(25% - #{$gapSizeXS});
  width: 14.4rem;

  &:hover {
    color: $primaryColor;
  }

  &__icon {
    @include wide-desktop-only {
      margin-bottom: 0;
      margin-left: 1.9rem;
      margin-right: $gapSizeS;
    }

    margin-bottom: 0.6rem;
    margin-right: 0;
  }
}
