@import 'src/styles/mixins';

.item {
  @include mobile-only {
    padding-right: 0;
    width: 100%;
  }

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  line-height: 2.4rem;
  margin-top: $gapSizeM;
  padding-right: $gapSizeS;
  width: 50%;

  &__title {
    @include font-size-m;

    align-items: center;
    color: $textColorLight;
    display: flex;
  }

  &__value {
    @include font-size-l;
  }

  &:first-child {
    border-bottom: 1px solid $lineColor;
    margin-top: 0;
    padding-bottom: $gapSizeXS;
    width: 100%;
  }

  &--summary {
    border-top: 1px solid $lineColor;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: $gapSizeM;
    padding-top: $gapSizeM;
    width: 100%;
  }

  &--full-width {
    width: 100%;
  }
}
