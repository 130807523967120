@import 'src/styles/mixins';

.rule {
  @include font-light;
  margin-bottom: $gapSizeXS;
}

.icon {
  @include icon-fill-color($textColorLight, 1);
  margin-right: $gapSizeXS;

  &--checked {
    @include icon-fill-color($primaryColor, 1);
  }
}
