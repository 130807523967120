@import 'src/styles/mixins';

.detail {
  margin-bottom: $gapSizeM;
  margin-top: 0;

  &:first-child {
    border-bottom: 0;
  }
}
