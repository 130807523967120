@import 'src/styles/mixins';

.description {
  @include font-size-m;
  color: $textColorLight;
}

.rules-header {
  @include font-size-s;
  color: $textColorLight;
  margin-bottom: $gapSizeXS;
}

.input {
  margin-bottom: $gapSizeL;
  margin-top: $gapSizeL;
  width: 100%;
}

.button {
  margin-top: $gapSizeS;
  width: 100%;
}

.error {
  @include mobile-only {
    text-align: center;
  }

  color: $dangerColor;
  margin-top: $gapSizeL;
}
