@import 'src/styles/variables';
@import 'icons';
@import 'helpers';

@mixin welcome-screen-errors {
  @include mobile-only {
    text-align: center;
  }

  color: $dangerColor;
  margin-bottom: 0;
  position: relative;

  & > div {
    @include desktop-only {
      position: absolute;
    }
  }
}


@mixin welcome-screen-error {
  @include mobile-only {
    margin-bottom: $gapSizeXL;
  }

  margin-bottom: $gapSizeM;
}

@mixin welcome-screen-action-link {
  @include link;
  margin-bottom: $gapSizeXL;
  text-align: center;
}

@mixin welcome-screen-top-link {
  @include link;
  @include wide-desktop-only {
    margin-right: $gapSizeXL;
  }

  margin-left: 0.6rem;
}
