@import 'src/styles/mixins';

.jumio {
  @include box;
  @include desktop-only {
    margin-top: $gapSizeXL;
    width: $boxWidthSizeL;
  }

  border: 0;
  margin-left: auto;
  margin-right: auto;
  min-height: 40rem;
  width: 100%;
}

.loader {
  margin-bottom: $gapSizeXL;
}
