@import 'src/styles/mixins';

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input {
  @include mobile-only {
    margin-bottom: $gapSizeL;
  }

  margin-bottom: $gapSizeXL;
  width: 100%;

  &--half {
    @include desktop-only {
      width: calc(50% - #{$gapSizeM / 2});
    }
  }
}

.date-picker {
  @include desktop-only {
    margin-left: $gapSizeXL;
  }
}

.label {
  @include only-on-desktop;

  margin-bottom: $gapSizeM;
  width: 100%;
}

.radio-group {
  @include desktop-only {
    flex-direction: row;
  }

  width: 100%;
}

.radio-label {
  @include font-size-s;
}
