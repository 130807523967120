@import 'src/styles/mixins';

.row {
  @include mobile-only {
    flex-direction: column;
  }

  align-items: flex-start;
  display: flex;

  &--margin-top {
    @include mobile-only {
      margin-top: 0;
    }

    margin-top: $gapSizeL;

    &:first-child {
      @include mobile-only {
        margin-top: 0;
      }

      margin-top: $gapSizeM;
    }
  }
}

$sideColumnWidth: $bannerDesktopWidth + $gapSizeL;

.side-column {
  @include box;
  margin-left: $gapSizeL;
  width: $sideColumnWidth;
}

.main-column {
  @include mobile-only {
    width: 100%;
  }

  width: calc(100% - #{$sideColumnWidth});

  &__box {
    @include mobile-only {
      min-height: 0;
      padding: $gapSizeM;
    }

    @include box;
    min-height: $bannerDesktopHeight;
  }
}
