@import 'src/styles/mixins';

.title {
  @include font-size-s;

  color: $textColorLight;
  margin-bottom: $gapSizeXS;

  &:not(:first-child) {
    margin-top: $gapSizeM;
  }
}

.option {
  @include default-transition;
  @include font-size-m;
  color: $textColor;
  cursor: pointer;
  display: block;
  padding-bottom: $gapSizeXXL / 4;
  padding-left: $gapSizeXS;
  padding-top: $gapSizeXXL / 4;

  &:not(:last-child) {
    border-bottom: 1px solid $lineColor;
  }

  &:hover {
    color: $primaryColor;
  }
}
