@import 'src/styles/mixins';

.container {
  @include desktop-only {
    max-width: $boxWidthSizeXS;
    padding-left: $gapSizeL + $gapSizeM;
    padding-right: $gapSizeL + $gapSizeM;
  }

  display: flex;
  flex-direction: column;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
}

.image {
  margin-bottom: $gapSizeS + $gapSizeM;
  margin-left: auto;
  margin-right: auto;
  margin-top: $gapSizeL * 2;
}

.title {
  @include font-size-xl;

  margin-bottom: $gapSizeS;
}

.description {
  @include font-size-m;
  @include font-light;

  color: $textColorLight;
  margin-bottom: $gapSizeS + $gapSizeXS;
}

.button {
  margin-bottom: $gapSizeL * 2;
}
