@import 'src/styles/mixins';

@mixin column-padding {
  box-sizing: border-box;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeL;
}

.title {
  @include popup-title;
  margin-bottom: 0;
}

.container {
  @include desktop-only {
    flex-direction: row;
    max-width: 100%;
    width: $boxWidthSizeXL;
  }

  display: flex;
  flex-direction: column;
}

.column {
  @include mobile-only {
    width: 100%;
  }

  width: 50%;
}

.loader {
  @include mobile-only {
    margin-bottom: $gapSizeXL;
  }

  margin-left: auto;
  margin-right: auto;
  margin-top: $gapSizeXL;
}

.amount {
  @include column-padding;
  @include desktop-only {
    min-height: 22.2rem;
  }

  &__title {
    @include font-size-l;
    margin-bottom: $gapSizeM;
  }

  &__tiles {
    @include small-mobile-only {
      grid-template-columns: repeat(3, 1fr);
    }

    @include default-transition;
    display: grid;
    grid-gap: $gapSizeM;
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: $gapSizeL;
  }
}

.tile {
  @include mobile-only {
    width: auto;
  }

  @include default-transition;
  @include size(6rem);
  align-items: center;
  border: 1px solid $primaryColor;
  border-radius: $smallBorderRadius;
  box-sizing: border-box;
  color: $primaryColor;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    background: $lightBackgroundColor;
  }
}

.reminders {
  @include column-padding;
  @include mobile-only {
    background: $lightBackgroundColor;
    padding-top: $gapSizeL;
  }

  padding-top: 0;

  &__title {
    @include font-size-m;
  }
}

.how-to {
  @include column-padding;
  @include mobile-only {
    padding-top: 0;
  }

  background: $lightBackgroundColor;
  padding-bottom: $gapSizeXL;

  &__icon {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
