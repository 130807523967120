@import 'src/styles/variables';
@import 'media-queries';

@mixin default-transition {
  transition: 0.2s ease;
}

@mixin default-box-shadow {
  box-shadow: 0 0 1.6rem 0.2rem $lineColor;
}

@mixin link {
  color: $primaryColor;
  cursor: pointer;
  text-decoration: underline;
}

@mixin transparent-button {
  background: transparent;
  border: 2px solid $primaryColor;
  color: $primaryColor;
}

@mixin size($size) {
  height: $size;
  width: $size;
}

@mixin font-size-xxl {
  font-size: map-get($fontSizes, xx-large);
}

@mixin font-size-xl {
  font-size: map-get($fontSizes, x-large);
}

@mixin font-size-l {
  font-size: map-get($fontSizes, large);
}

@mixin font-size-m {
  font-size: map-get($fontSizes, medium);
}

@mixin font-size-s {
  font-size: map-get($fontSizes, small);
}

@mixin font-size-xs {
  font-size: map-get($fontSizes, x-small);
}

@mixin font-bold {
  font-weight: map-get($fontWeights, bold);
}

@mixin font-light {
  font-weight: map-get($fontWeights, light);
}

@mixin only-on-desktop {
  @include mobile-only {
    display: none;
  }
}

@mixin only-on-mobile {
  @include desktop-only {
    display: none;
  }
}

@mixin text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
