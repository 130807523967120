@import 'src/styles/mixins';

// mixin for positioning content-spinner graphic elements
@mixin loader-circles($scale: 1) {
  height: (6.4rem * $scale);
  width: (6.4rem * $scale);

  .fragment {
    height: (0.5rem * $scale);
    width: (0.5rem * $scale);

    &:nth-child(1) {
      animation-delay: 0s;
      left: (5.3rem * $scale);
      top: (2.9rem * $scale);
    }

    &:nth-child(2) {
      animation-delay: -0.1s;
      left: (5rem * $scale);
      top: (1.8rem * $scale);
    }

    &:nth-child(3) {
      animation-delay: -0.2s;
      left: (4.1rem * $scale);
      top: (0.9rem * $scale);
    }

    &:nth-child(4) {
      animation-delay: -0.3s;
      left: (2.9rem * $scale);
      top: (0.6rem * $scale);
    }

    &:nth-child(5) {
      animation-delay: -0.4s;
      left: (1.8rem * $scale);
      top: (0.9rem * $scale);
    }

    &:nth-child(6) {
      animation-delay: -0.5s;
      left: (0.9rem * $scale);
      top: (1.8rem * $scale);
    }

    &:nth-child(7) {
      animation-delay: -0.6s;
      left: (0.6rem * $scale);
      top: (2.9rem * $scale);
    }

    &:nth-child(8) {
      animation-delay: -0.7s;
      left: (0.9rem * $scale);
      top: (4.1rem * $scale);
    }

    &:nth-child(9) {
      animation-delay: -0.8s;
      left: (1.8rem * $scale);
      top: (5rem * $scale);
    }

    &:nth-child(10) {
      animation-delay: -0.9s;
      left: (2.9rem * $scale);
      top: (5.3rem * $scale);
    }

    &:nth-child(11) {
      animation-delay: -1s;
      left: (4.1rem * $scale);
      top: (5rem * $scale);
    }

    &:nth-child(12) {
      animation-delay: -1.1s;
      left: (5rem * $scale);
      top: (4.1rem * $scale);
    }
  }
}

.content-spinner {
  @include loader-circles;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  &--margin {
    @include mobile-only {
      margin-bottom: $gapSizeL;
      margin-top: $gapSizeL;
    }

    margin-bottom: $gapSizeXL;
    margin-top: $gapSizeXL;
  }

  .fragment {
    @keyframes loader--default {
      0%,
      20%,
      80%,
      100% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.5);
      }
    }

    animation: loader--default 1.2s linear infinite;
    background: $textColorLight;
    border-radius: 50%;
    position: absolute;
  }
}

.button-spinner {
  align-items: center;
  border-radius: $smallBorderRadius;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  height: calc(100% + 2px);
  left: -1px; // sass-lint:disable-line property-units
  pointer-events: none;
  position: absolute;
  top: -1px; // sass-lint:disable-line property-units
  width: calc(100% + 2px);

  .fragment {
    @keyframes loader--ring {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    animation: loader--ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $white transparent transparent;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    height: 1.6rem;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    width: 1.6rem;

    &--primary {
      border-color: $primaryColor transparent transparent;
    }

    &--text-color {
      border-color: $textColorLight transparent transparent;
    }

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

