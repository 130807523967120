@import 'src/styles/mixins';

.row {
  @include default-transition;
  @include mobile-only {
    height: auto;
    padding-bottom: $gapSizeS;
    padding-top: $gapSizeS;
  }

  border: 1px solid $lineColor;
  border-radius: $smallBorderRadius;
  box-sizing: border-box;
  column-gap: $gapSizeM;
  cursor: pointer;
  display: grid;
  grid-template-areas:
  'logo title arrow'
  'logo description arrow';
  grid-template-columns: 5.6rem auto 2.4rem;
  height: 7.2rem;
  padding-bottom: $gapSizeXS;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeXS;

  &:hover {
    background: $lightBackgroundColor;
  }
}

.logo-container {
  @include size(5.6rem);

  align-content: center;
  display: flex;
  grid-area: logo;
  justify-content: center;

  &__logo {
    max-height: 100%;
    max-width: 100%;
  }
}

.title {
  @include font-size-l;

  grid-area: title;
}

.arrow-container {
  align-content: center;
  display: flex;
  flex-direction: column;
  grid-area: arrow;
  justify-content: center;

  img {
    flex-basis: min-content; // fix grow issue on Mozilla
  }
}

.description {
  @include mobile-only {
    margin-top: $gapSizeXXS;
  }

  @include desktop-only {
    @include text-overflow-ellipsis;
  }

  @include font-size-m;
  @include font-light;

  color: $textColorLight;
  grid-area: description;
}
