@import 'src/styles/variables';

@mixin pretty-scrollbar {
  // sass-lint:disable no-vendor-prefixes
  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-track {
    background: $lightBackgroundColor;
  }

  &::-webkit-scrollbar-thumb {
    @include default-transition;
    background: $primaryColor;
    border-radius: $bigBorderRadius;
  }
  // sass-lint:enable no-vendor-prefixes
}
