@import 'src/styles/mixins';

// TODO make this styles default in OperationDetail
.detail {
  width: 100%;

  &:first-child {
    border-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
}

.link {
  @include font-size-m;
  @include link;

  display: flex;
  justify-content: center;
  min-width: 7rem;
  position: relative;
}

.amount {
  display: flex;
  justify-content: space-between;
}
