// sass-lint:disable no-duplicate-properties

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Nunito-Regular.eot');
  src: url('../assets/fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/Nunito-Regular.woff') format('woff'), url('../assets/fonts/Nunito-Regular.ttf')  format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Nunito-SemiBold.eot');
  src: url('../assets/fonts/Nunito-SemiBold.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/Nunito-SemiBold.woff') format('woff'), url('../assets/fonts/Nunito-SemiBold.ttf')  format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Nunito-Bold.eot');
  src: url('../assets/fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),  url('../assets/fonts/Nunito-Bold.woff') format('woff'), url('../assets/fonts/Nunito-Bold.ttf')  format('truetype');
}
