@import 'src/styles/mixins';

$basicInfoDesktopWidth: 26.4rem;

.main-container {
  @include desktop-only {
    min-width: $boxWidthSizeL;
  }

  min-height: 30rem;
}

.title {
  @include popup-title;
}

.details {
  @include mobile-only {
    border: 1px solid $lineColor;
    border-radius: $bigBorderRadius;
    grid-template-areas:
    'image name name name'
    'progress progress progress progress'
    'amount amount duration duration'
    'dates dates dates dates'
    'amount-per-month amount-per-month amount-per-month amount-per-month';
    grid-template-columns: 5.6rem auto 25% 25%;
    margin-left: $gapSizeL;
    margin-right: $gapSizeL;
    margin-top: $gapSizeL;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
    padding-top: $gapSizeL;
  }

  @include desktop-only {
    background: linear-gradient(90deg, $white 0 51.6%, $lightBackgroundColor 50%);
    grid-template-columns: auto 32.8rem;
    padding-bottom: 2 * $gapSizeL;
  }

  display: grid;
  grid-row-gap: $gapSizeM;
  grid-template-areas:
  'name progress'
  'amount image'
  'duration image'
  'dates image'
  'delete-link amount-per-month';
  padding-left: $gapSizeXL;
  padding-top: $gapSizeXL;
  position: relative;
}

.detail {
  display: flex;
  flex-direction: column;
  height: 4.8rem;
  justify-content: space-around;

  &--name {
    grid-area: name;
  }

  &--amount {
    grid-area: amount;
  }

  &--duration {
    grid-area: duration;
  }

  &--dates {
    grid-area: dates;
  }

  &__label {
    @include mobile-only {
      @include font-size-s;
    }

    @include font-size-m;

    color: $textColorLight;
  }

  &__value {
    @include mobile-only {
      @include font-size-l;
    }

    @include font-size-xl;

    color: $textColor;
  }
}

.delete-link-container {
  @include font-size-m;

  @include mobile-only {
    margin-bottom: $gapSizeM;
    margin-top: $gapSizeM;
    text-align: center;
  }

  &--desktop {
    @include mobile-only {
      display: none;
    }
  }

  &--mobile {
    @include desktop-only {
      display: none;
    }
  }

  &__link {
    cursor: pointer;
  }

  align-self: center;
  border-radius: $smallBorderRadius;
  color: $primaryColor;
  display: flex;
  flex-direction: column;
  grid-area: delete-link;
  height: 4.8rem;
  justify-content: center;
  text-align: start;
  text-decoration: underline;
}

.progress-container {
  @include desktop-only {
    height: 4.8rem;
    justify-self: center;
    width: $basicInfoDesktopWidth;
  }

  @include mobile-only {
    align-items: center;
    border-bottom: 1px solid $lineColor;
    justify-content: center;
    padding-bottom: $gapSizeL;
  }

  display: flex;
  flex-direction: column-reverse;
  grid-area: progress;
  justify-content: space-between;
  text-align: center;

  &__info {
    @include font-size-s;

    @include mobile-only {
      position: absolute;
    }

    color: $textColorLight;

    &--nearly-finished {
      @include mobile-only {
        color: $white;
      }
    }
  }
}

.image-container {
  @include mobile-only {
    margin-right: $gapSizeM;
    max-height: 4rem;
    width: 4rem;
  }

  @include desktop-only {
    margin-bottom: $gapSizeXS;
    margin-top: $gapSizeXS;
  }

  align-items: center;
  align-self: center;
  display: flex;
  grid-area: image;
  height: 16rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: $basicInfoDesktopWidth;
}

.image {
  @include mobile-only {
    border: 1px solid $lineColor;
    border-radius: 100%;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  border-radius: $bigBorderRadius;
  max-height: 100%;
  max-width: 100%;
}

.icon {
  max-height: 80%;
  width: 100%;
}

.amount-per-month-container {
  @include desktop-only {
    width: $basicInfoDesktopWidth;
  }

  @include mobile-only {
    border-top: 1px solid $lineColor;
    padding-bottom: $gapSizeL;
    padding-top: $gapSizeL;
  }

  grid-area: amount-per-month;
  justify-self: center;
  width: 100%;
}

.amount-per-month {
  background: $primaryBackgroundColor;
  padding-bottom: $gapSizeS;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeS;
  width: $basicInfoDesktopWidth;

  &__label {
    @include font-size-s;
    color: $textColorLight;
  }

  &__value {
    @include font-size-xl;
    color: $primaryColor;
  }
}
