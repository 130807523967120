@import 'src/styles/mixins';

$goalIconSize: 5.6rem;
$goalIconSizeMobile: 4rem;

.container {
  @include mobile-only {
    border-radius: $bigBorderRadius;
    grid-template-areas:
    'image information'
    'progress progress';
    grid-template-columns: ($goalIconSizeMobile + $gapSizeM) auto;
    grid-template-rows: $goalIconSizeMobile $goalIconSizeMobile;
    padding-bottom: $boxSidePadding;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
    padding-top: $boxSidePadding;
  }

  @include default-transition;
  border: 2px solid $lineColor;
  border-radius: $smallBorderRadius;
  cursor: pointer;
  display: grid;
  grid-template-areas:
  'image information'
  'image progress';
  grid-template-columns: ($goalIconSize + $boxSidePadding) auto;
  grid-template-rows: $goalIconSize/2 $goalIconSize/2;
  padding: $boxSidePadding;

  &:hover {
    background: $lightBackgroundColor;
  }

  & + .container {
    margin-top: $gapSizeM;
  }
}

.image-container {
  @include mobile-only {
    @include size(4rem);
  }

  @include size(5.6rem);
  display: flex;
  grid-area: image;

  &__image {
    border: 1px solid $lineColor;
    border-radius: 100%;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.basic-info {
  @include mobile-only {
    flex-direction: column;
  }

  @include desktop-only {
    align-items: center;
  }

  display: flex;
  grid-area: information;
  justify-content: space-between;
  width: 100%;
}

.name {
  @include mobile-only {
    @include font-size-m;
  }

  @include font-size-l;

  color: $textColor;
}

.amount {
  @include mobile-only {
    @include font-size-s;
  }

  @include font-size-m;
  @include font-light;

  color: $textColorLight;
}

.progress-container {
  @include mobile-only {
    margin-top: $gapSizeM;
  }

  align-items: center;
  display: flex;
  grid-area: progress;
  justify-content: center;

  &__info {
    @include font-size-s;
    @include font-light;

    color: $textColorLight;
    position: absolute;
    z-index: $zIndexPrioritized;

    &--nearly-finished {
      color: $white;
    }
  }
}
