@import 'src/styles/mixins';

/*
*  Global styles changing / overriding MaterialUI styles
*/

.MuiButton-root,
.MuiOutlinedInput-root {
  border-radius: $smallBorderRadius;
  min-height: 4.8rem;
  min-width: 16rem;
}

.MuiButton-outlinedPrimary {
  border-width: 2px !important; // sass-lint:disable-line no-important
}

.MuiOutlinedInput-input {
  @include mobile-only {
    @include font-size-s;
  }

  @include font-size-l;
}

.MuiFormControlLabel-label {
  @include font-light;
  @include font-size-l;
}

.MuiSelect-select {
  line-height: 2rem;

  &.Mui-disabled {
    @include font-light;
    background: $lightBackgroundColor;
    color: $textColor;
  }
}

.MuiFormHelperText-root {
  @include font-size-s;

  margin-top: $gapSizeXS;
}

// override size of inputs placeholders on mobile
.MuiInputLabel-root {
  @include font-size-l;
  @include text-overflow-ellipsis;
  max-width: 120%; // max-width slightly bigger for label that is shrinked

  &:not(.MuiInputLabel-shrink) {
    @include mobile-only {
      font-size: map-get($fontSizes, small) !important; // sass-lint:disable-line no-important
    }

    max-width: calc(100% - 2 * #{$gapSizeM});
  }
}

.MuiInputAdornment-root {
  .MuiTypography-root {
    @include mobile-only {
      @include font-size-s;
    }

    @include font-size-m;
    color: $textColor;
  }
}

.MuiOutlinedInput-notchedOutline {
  @include default-transition;

  // to fix bugged, too small gap for placeholder when adornment added to TextField
  legend {
    @include font-size-s;
  }
}

.MuiPaper-rounded {
  @include desktop-only {
    border-radius: $smallBorderRadius;
  }
}

.MuiStepConnector {
  &-vertical {
    padding-bottom: 0;
  }

  .MuiStepConnector-line {
    border-color: $stepperLightColor;
  }

  &-active {
    .MuiStepConnector-line {
      border-color: $primaryColor;
    }
  }
}

.MuiMenu-paper {
  @include pretty-scrollbar;
}

.MuiPaper-rounded,
.MuiDialog-paper {
  @include pretty-scrollbar;
  overflow-x: hidden;

  // sass-lint:disable no-vendor-prefixes
  &::-webkit-scrollbar {
    border-radius: $bigBorderRadius;
  }

  &::-webkit-scrollbar-track {
    border-radius: $bigBorderRadius;
  }
  // sass-lint:enable no-vendor-prefixes
}
