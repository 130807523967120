@import 'src/styles/mixins';

.header {
  @include mobile-only {
    align-items: center;
    display: flex;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
  }

  @include desktop-only {
    @include default-box-shadow;
  }

  background: $white;
}

.container {
  @include desktop-only {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include desktop-width;
  display: flex;
  height: $headerHeight;
  justify-content: center;
}

.account-dropdown {
  @include mobile-only {
    display: none;
  }
}

.logo-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:first-child {
    justify-content: flex-start;
  }
}

.logo {
  max-height: 4.5rem;
}

.back {
  @include mobile-only {
    height: $headerHeight;
    left: 0;
    padding-left: $gapSizeM;
    position: absolute;
  }

  @include link;
  align-items: center;
  display: flex;

  &__icon {
    margin-right: $gapSizeS;
  }

  &__label {
    @include mobile-only {
      display: none;
    }
  }
}
