@import 'mixins';

html {
  font-size: $baseFontSize;
}

body {
  @include font-size-m;
  background: $lightBackgroundColor;
  color: $textColor;
  margin: 0;
  overflow-y: scroll;
  padding: 0;

  * {
    font-family: $mainFontFamily;
  }
}

h1 {
  @include mobile-only {
    @include font-size-xl;
  }

  @include font-size-xxl;
  font-weight: map-get($fontWeights, normal);
  line-height: 3.4rem;
  margin-bottom: $gapSizeM;
  margin-top: 0;
}

h2 {
  @include font-size-l;
  font-weight: map-get($fontWeights, normal);
  margin-bottom: $gapSizeL;
  margin-top: 0;
}

p {
  @include font-size-l;
  margin-bottom: 0;
  margin-top: 0;
}

a {
  outline: none;
  text-decoration: none;
}
