@import 'src/styles/variables';

@mixin small-mobile-only {
  @media screen and (max-width: $breakpointSm + 'px') {
    @content
  }
}

@mixin mobile-only {
  @media screen and (max-width: $breakpointMd + 'px') {
    @content
  }
}

@mixin desktop-only {
  @media screen and (min-width: ($breakpointMd + 1) + 'px') {
    @content
  }
}

@mixin wide-desktop-only {
  @media screen and (min-width: ($breakpointLg + 1) + 'px') {
    @content
  }
}
