@import 'src/styles/mixins';

.container {
  @include desktop-only {
    max-width: 100%;
    width: 77rem;
  }

  @include mobile-only {
    flex-direction: column;
  }

  display: flex;
  flex-direction: row;
}

.column {
  @include mobile-only {
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  width: 48%;

  & + .column {
    @include mobile-only {
      width: 100%;
    }

    width: 52%;
  }

  &__content-wrapper {
    padding: $gapSizeXL;
  }
}

.icon-wrapper {
  @include mobile-only {
    padding-left: $gapSizeXL;
    padding-right: $gapSizeXL;
    padding-top: $gapSizeXXL;
  }

  display: flex;
  justify-content: center;
  padding-top: $gapSizeXL;
}

.reference-number {
  align-items: center;
  background: $primaryBackgroundColor;
  display: flex;
  flex-direction: column;
  padding-bottom: $gapSizeL;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeM;

  &__value {
    color: $primaryColor;
    font-size: 2.8rem;
    letter-spacing: 0.4rem;
    text-align: center;
  }

  &__label {
    @include font-light;
    @include font-size-s;
    color: $textColorLight;
    margin-bottom: $gapSizeS;
  }
}

.how-to {
  background: $lightBackgroundColor;
  box-sizing: border-box;
  padding: $gapSizeXL;

  &__icon {
    @include desktop-only {
      img {
        min-width: 28rem;
      }
    }

    align-items: center;
    display: flex;
    justify-content: center;
  }
}
