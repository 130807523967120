@import 'src/styles/mixins';

.header {
  @include mobile-only {
    margin-bottom: 0;
    margin-top: $gapSizeL;
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
    text-align: left;
  }

  margin-bottom: $gapSizeXXS;
  text-align: center;
}

.description {
  @include mobile-only {
    display: none;
  }

  @include font-size-m;
  color: $textColorLight;
  margin-bottom: $gapSizeXL;
  text-align: center;
}

.box {
  @include box;
  @include mobile-only {
    margin-left: auto;
    margin-right: auto;
    padding: $gapSizeL;
  }

  @include small-mobile-only {
    padding-left: $gapSizeM;
    padding-right: $gapSizeM;
  }

  @include desktop-only {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 100%;
    padding: 0;
    width: 77.6rem;
  }

  margin-left: auto;
  margin-right: auto;

  &__content {
    @include desktop-only {
      display: flex;
      flex: 1;
      height: 100%;

      form {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    }
  }
}

.stepper {
  @include desktop-only {
    border-right: 1px solid $lineColor;
    width: 23.3rem;
  }
}

.step {
  display: flex;
  flex: 1;
  flex-direction: column;

  &__header {
    @include desktop-only {
      border-bottom: 1px solid $lineColor;
      box-shadow: 0 0.1rem 1.3rem -0.5rem $lineColor;
    }

    @include mobile-only {
      @include font-size-xl;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      padding-top: $gapSizeL;
    }

    margin-bottom: 0;
    padding-bottom: $gapSizeM;
    padding-left: $gapSizeXL;
    padding-right: $gapSizeXL;
    padding-top: $gapSizeM;
  }

  &__description {
    @include font-size-m;
    color: $textColorLight;
    margin-bottom: $gapSizeL;
  }

  &__content-wrapper {
    @include desktop-only {
      flex: 1;
      height: 30rem; // height here works as min-height (flex will stretch container as much as possible)
    }
  }

  &__content {
    @include mobile-only {
      padding-left: 0;
      padding-right: 0;
    }

    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: $gapSizeL;
    padding-left: $gapSizeXL;
    padding-right: $gapSizeXL;
    padding-top: $gapSizeL;
  }
}
