@import 'src/styles/mixins';

.categories {
  @include mobile-only {
    grid-template-columns: repeat(auto-fill, minmax($smallMobileTileSize, 1fr));
    margin-left: 0;
    margin-right: 0;
  }

  display: grid;
  grid-column-gap: $gapSizeM;
  grid-row-gap: $gapSizeM;
  grid-template-columns: repeat(auto-fill, minmax($tileSize, 1fr));
  margin-bottom: $gapSizeL;
  margin-left: $gapSizeS;
  margin-right: $gapSizeS;
  margin-top: $gapSizeL;
}
