@import 'src/styles/mixins';


.container {
  @include mobile-only {
    padding-bottom: $gapSizeXXL;
  }

  @include desktop-only {
    flex-direction: row;
    max-width: 100%;
    width: $boxWidthSizeXL;
  }

  display: flex;
  flex-direction: column;
}

.column {
  @include mobile-only {
    width: 100%;
  }

  width: 50%;
}

.title {
  @include popup-title;
}

.form {
  @include mobile-only {
    padding-bottom: $mobileBottomMenuHeight;
  }

  @include desktop-only {
    min-width: $boxWidthSizeXS;
  }

  display: flex;
  flex-direction: column;
}

.input-container {
  @include mobile-only {
    padding-bottom: 0;
  }

  @include popup-body;
  padding-bottom: $gapSizeXL;
  padding-top: 0;
}

.actions {
  @include popup-footer;

  @include desktop-only {
    background: none;
    border: 0;
    box-shadow: none;
    justify-content: center;
    padding-bottom: $gapSizeXL;
  }
}

.submit {
  @include mobile-only {
    width: 100%;
  }

  width: 22.4rem;
}

.reminders {
  @include mobile-only {
    padding-bottom: $gapSizeXL;
    padding-top: $gapSizeL;
  }

  @include desktop-only {
    height: 100%;
  }

  background: $lightBackgroundColor;
  box-sizing: border-box;
  padding-left: $gapSizeXL;
  padding-right: $gapSizeXL;
  padding-top: $gapSizeXL;

  &__title {
    @include font-size-l;
  }
}

.icon-wrapper {
  @include mobile-only {
    border-bottom: 1px solid $lineColor;
    margin-bottom: $gapSizeM;
    margin-top: $gapSizeM;
  }

  @include font-size-l;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: $gapSizeXL;
  margin-top: $gapSizeXL;
  padding-bottom: $gapSizeM;

  img {
    padding-bottom: $gapSizeM;
    padding-top: $gapSizeM;
  }
}
