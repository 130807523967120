@import 'src/styles/mixins';

.container {
  @include desktop-only {
    min-height: $dashboardServicesRowHeight;
  }
}

.activities {
  border-top: 1px solid $lineColor;
}
