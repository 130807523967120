@import 'src/styles/mixins';

.container {
  @include desktop-only {
    min-width: $boxWidthSizeM;
  }
}

.title {
  @include popup-title;
}

.form {
  @include mobile-only {
    padding-bottom: $mobileBottomMenuHeight;
  }

  display: flex;
  flex-direction: column;
}

.input-container {
  @include popup-body;

  & > * {
    margin-bottom: $gapSizeXL;

    &:first-child {
      margin-top: $gapSizeXL;
    }
  }
}

.actions {
  @include popup-footer;
}

.submit {
  @include mobile-only {
    width: 100%;
  }
}
