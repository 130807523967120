@import 'src/styles/mixins';

.icon {
  @include small-mobile-only {
    width: 4.8rem;
  }

  height: auto;
  max-width: 100%;
  width: 6.4rem;
}
