@import 'src/styles/mixins';

.title {
  @include font-size-xxl;
  margin-top: $gapSizeM;
}

.list {
  @include mobile-only {
    margin-bottom: 0;
    padding-bottom: $gapSizeM;
    padding-right: $gapSizeM;
  }

  margin-bottom: $gapSizeM;
  margin-top: $gapSizeM;
  padding-left: $gapSizeL;

  &__item {
    @include font-light;
    @include font-size-m;
    color: $textColorLight;
  }
}
