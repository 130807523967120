@import 'src/styles/mixins';

.banner {
  @include mobile-only {
    height: auto;
    margin-left: $gapSizeM;
    margin-right: $gapSizeM;
    margin-top: $gapSizeL;
    padding: 0;
    width: calc(100% - 2 * #{$gapSizeM});
  }

  @include small-mobile-only {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  @include default-box-shadow;
  align-items: center;
  background: none;
  border-radius: $bigBorderRadius;
  cursor: pointer;
  display: flex;
  height: $bannerDesktopHeight;
  justify-content: center;
  margin-left: $gapSizeL;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: $bannerDesktopWidth;
}

.image {
  @include small-mobile-only {
    border-radius: 0;
  }

  border-radius: $bigBorderRadius;
  width: 100%;

  &--mobile {
    @include only-on-mobile;
    @include mobile-only {
      width: 100%;
    }
  }

  &--desktop {
    @include only-on-desktop;
  }
}
