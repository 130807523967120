@import 'src/styles/mixins';

.main-container {
  @include mobile-only {
    background: $white;
    grid-template-areas:
    'hero-image'
    'success-message'
    'instructions'
    'buttons';
  }

  background: $lightBackgroundColor;
  display: grid;
  grid-template-areas:
  'hero-image instructions'
  'success-message instructions'
  'buttons instructions';
  justify-items: center;
}

.hero-image {
  @include mobile-only {
    margin-bottom: $gapSizeL;
    margin-top: $gapSizeL;
    width: 20rem;
  }

  grid-area: hero-image;
  margin-top: $gapSizeXXL;
  width: 22rem;
}

.success-message {
  @include mobile-only {
    box-sizing: border-box;
    padding-left: $gapSizeL;
    padding-right: $gapSizeL;
    width: 100%;
  }

  @include desktop-only {
    margin-bottom: $gapSizeL;
    margin-left: $gapSizeXL;
    margin-right: $gapSizeXL;
    margin-top: $gapSizeXL;
  }

  @include font-size-xl;

  grid-area: success-message;
  width: 27.2rem;
}

.buttons {
  @include mobile-only {
    box-sizing: border-box;
    padding-left: $gapSizeL;
    padding-right: $gapSizeL;
    width: 100%;
  }

  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: buttons;
  margin-left: $gapSizeXL;
  margin-right: $gapSizeXL;
  padding-bottom: $gapSizeXXL;
  width: 25.6rem;

  &__product-provider-link {
    @include transparent-button;
    @include default-transition;
    @include font-size-l;

    margin-bottom: $gapSizeM;
    width: 100%;

    &:hover {
      background: $white;
    }
  }

  &__back-link {
    @include link;

    @include mobile-only {
      background: transparent;
    }

    background: $lightBackgroundColor;
  }
}

.instructions-container {
  @include desktop-only {
    padding-top: $gapSizeXXL;
  }

  @include mobile-only {
    box-sizing: border-box;
    padding-left: $gapSizeL;
    padding-right: $gapSizeL;
    width: 100%;
  }

  background: $white;
  grid-area: instructions;
  padding-left: $gapSizeL;
  padding-right: $gapSizeL;
  width: 30.4rem;
}

.instructions-title {
  @include mobile-only {
    margin-top: $gapSizeM;
  }

  @include font-size-m;
}

.plan-code {
  @include desktop-only {
    margin-top: $gapSizeXL;
  }

  @include mobile-only {
    margin-bottom: $gapSizeM;
  }

  align-items: center;
  border: 1px solid $lineColor;
  border-radius: $smallBorderRadius;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: $gapSizeM;
  padding-top: $gapSizeM;

  &__title {
    @include font-size-s;

    color: $textColorLight;
    margin-bottom: $gapSizeXS;
  }

  &__code {
    @include font-size-xxl;

    color: $primaryColor;
  }
}
