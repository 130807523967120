@import 'src/styles/mixins';

.description {
  @include font-size-m;
  color: $textColorLight;
  margin-bottom: $gapSizeL;
}

.input {
  margin-bottom: $gapSizeM;
  margin-top: $gapSizeM;
  width: 100%;
}

.button {
  margin-top: $gapSizeL;
  width: 100%;
}

.date-picker {
  margin-left: $gapSizeM;
  margin-top: $gapSizeS;
}
