@import 'src/styles/mixins';

$basicInfoDesktopWidth: 26.4rem;

.main-container {
  @include desktop-only {
    background: $lightBackgroundColor;
  }
}

.heading {
  @include mobile-only {
    @include font-size-l;
  }
  @include font-size-xxl;
  @include popup-title;

  background: $white;
  position: relative;
}

.form-fields {
  @include mobile-only {
    grid-template-areas:
    'file-upload'
    'inputs'
    'amount-per-month';
    justify-items: center;
  }

  @include desktop-only {
    grid-template-areas:
    'inputs file-upload'
    'inputs amount-per-month';
  }

  display: grid;
}

.inputs {
  background: $white;
  display: flex;
  flex-direction: column;
  grid-area: inputs;
}

.input {
  margin-left: $gapSizeXL;
  margin-right: $gapSizeXL;
  width: 29.6rem;

  &--name {
    @include desktop-only {
      margin-top: $gapSizeXL;
    }

    margin-bottom: $gapSizeL;
  }

  &--amount {
    margin-bottom: $gapSizeL;
  }

  &--month-duration {
    margin-bottom: $gapSizeL;
  }

  &--start-date {
    @include mobile-only {
      margin-bottom: $gapSizeM;
    }
  }
}

.file-upload {
  @include mobile-only {
    @include size(7.2rem);
    margin-bottom: 2 * $gapSizeXL;
  }

  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-area: file-upload;
  height: 23rem;
  justify-content: center;
  margin-bottom: $gapSizeL;
  margin-left: $gapSizeXL;
  margin-right: $gapSizeXL;
  margin-top: $gapSizeXL;
  position: relative;
  width: $basicInfoDesktopWidth;

  &--empty {
    background: $fileUploadBackgroundColor;
    border: 2px dashed $primaryColor;
    border-radius: $bigBorderRadius;
  }

  &__icon {
    @include mobile-only {
      @include size($gapSizeL);
    }

    @include desktop-only {
      margin-bottom: $gapSizeM;
    }

    &--filled {
      position: absolute;
    }
  }

  &__label {
    @include font-size-s;
    @include mobile-only {
      margin-top: 5 * $gapSizeL;
      position: absolute;
    }

    color: $textColorLight;
    white-space: nowrap;

    &--hidden-on-desktop {
      @include desktop-only {
        display: none;
      }
    }
  }

  &__image {
    border-radius: $bigBorderRadius;
    max-height: 100%;
    max-width: 100%;
  }
}

.amount-per-month-container {
  @include mobile-only {
    width: 29.6rem;
  }

  grid-area: amount-per-month;
  margin-bottom: $gapSizeL;
  margin-left: $gapSizeXL;
  margin-right: $gapSizeXL;
  width: $basicInfoDesktopWidth;
}

.footer {
  @include popup-footer;

  position: relative;
}
