@import 'src/styles/mixins';

.register {
  @include mobile-only {
    grid-template-areas:
    'form'
    'register';
    margin-left: 0;
  }

  border-radius: $smallBorderRadius;
  display: grid;
  grid-template-areas:
  'register'
  'form'
  '.';
  height: 100%;
  margin-left: 6rem;
}

.form-container {
  @include desktop-only {
    margin-top: 10rem;
    max-width: 34rem;
  }

  grid-area: form;
  min-height: 30rem;
  position: relative;
}

.form-header {
  @include small-mobile-only {
    line-height: 3.2rem;
  }

  margin-bottom: $gapSizeS;
}

.form-description {
  color: $textColorLight;
  margin-bottom: $gapSizeXL;
}

.form {
  display: flex;
  flex-direction: column;
}

.input {
  margin-top: $gapSizeXS;
  min-height: 8.5rem;
  width: 100%;
}

.loader {
  margin-top: $gapSizeL;
}

.login {
  @include mobile-only {
    margin-top: $gapSizeXL;
    text-align: center;
  }

  display: flex;
  grid-area: register;
  justify-content: flex-end;
  text-align: right;

  &__link {
    @include welcome-screen-top-link;
  }
}

.errors {
  @include welcome-screen-errors;
}

.error {
  @include welcome-screen-error;
}

.invite-code-link {
  @include welcome-screen-action-link;
}

.welcome {
  color: $textColorLight;
  margin-bottom: $gapSizeM;
}

.invite-code-popup {
  @include mobile-only {
    width: auto;
  }

  box-sizing: border-box;
  height: 57rem;
  padding: $gapSizeXL;
  width: 37rem;
}
