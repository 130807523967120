@import 'src/styles/mixins';

.container {
  @include desktop-only {
    max-width: 100%;
    width: $boxWidthSizeXL;
  }
}

.title {
  @include popup-title-with-back-arrow;
}

.arrow-back {
  @include popup-arrow-back;
}

.form {
  @include mobile-only {
    padding-bottom: $mobileBottomMenuHeight;
  }

  display: flex;
  flex-direction: column;
}

.input-container {
  @include popup-body;
}

.actions {
  @include popup-footer;
}

.submit {
  @include mobile-only {
    width: 100%;
  }
}

.section {
  padding-bottom: $gapSizeM;

  &__title {
    @include font-size-l;
    margin-bottom: 0.4rem;
  }

  &__description {
    @include font-size-m;
    color: $textColorLight;
    margin-bottom: $gapSizeS;
  }

  &__container {
    @include mobile-only {
      grid-template-columns: 100%;
    }

    box-sizing: border-box;
    display: grid;
    grid-column-gap: $gapSizeM;
    grid-row-gap: $gapSizeM;
    grid-template-columns: auto auto;
    padding-bottom: $gapSizeM;
    padding-top: $gapSizeM;
  }
}
