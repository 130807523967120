@import 'src/styles/mixins';

.title {
  @include popup-title
}

.container {
  @include popup-body;
  @include desktop-only {
    height: 50rem;
    max-width: $boxWidthSizeL;
  }
}

.description {
  margin-top: $gapSizeL;
}

// This style is passed to MuiOutlinedInput. In global css we overwrite border-radius so it has to be important.
.search-box {
  background: $lightBackgroundColor;
  border-radius: 3rem !important; // sass-lint:disable-line no-important
  margin-top: $gapSizeXS;
}

.no-border-override {
  border: 0;
}

.categories {
  margin-bottom: $gapSizeL;
  margin-top: $gapSizeL;
}

.product-row {
  @include default-transition;
  border: 1px solid $lineColor;
  border-bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: $gapSizeL;

  &__label {
    @include text-overflow-ellipsis;
    max-width: 100%;
  }

  &:hover {
    background: $lightBackgroundColor;
  }

  &:first-child {
    border-top-left-radius: $bigBorderRadius;
    border-top-right-radius: $bigBorderRadius;
  }

  &:last-child {
    border-bottom: 1px solid $lineColor;
    border-bottom-left-radius: $bigBorderRadius;
    border-bottom-right-radius: $bigBorderRadius;
  }
}

.arrow-icon {
  margin-left: $gapSizeM;
}
