@import 'src/styles/mixins';

.container {
  @include mobile-only {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - #{$mobileFooterHeight} - #{$mobileBottomMenuHeight} - #{$headerHeight});
    padding-bottom: $gapSizeL;
    padding-top: 0;
  }

  @include desktop-width;
  box-sizing: border-box;
  min-height: calc(100vh - #{$footerHeight} - #{$headerHeight});
  padding-bottom: 2 * $gapSizeL;
  padding-top: $gapSizeL;
}
