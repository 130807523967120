@import 'src/styles/mixins';

.back-link {
  @include link;
  margin-top: $gapSizeM;
  padding-bottom: $gapSizeL;
}

.empty-state {
  color: $textColorLight;
  display: flex;
  flex-direction: column;
  margin-bottom: $gapSizeXL;
  margin-top: $gapSizeXL;
  text-align: center;
}
