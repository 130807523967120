@import 'src/styles/mixins';

.container {
  display: flex;
  justify-content: space-between;
}

.header {
  color: $textColor;
  margin-bottom: $gapSizeM;
}

.view-all-link {
  @include link;
}
