@import 'src/styles/mixins';

.checkboxes {
  @include mobile-only {
    margin-bottom: $gapSizeL;
  }

  @include default-transition;
  border: 1px solid $lineColorDarker;
  border-radius: $smallBorderRadius;
  margin-bottom: $gapSizeXL;
  padding-bottom: $gapSizeS;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
  padding-top: $gapSizeXS;

  &--error {
    border-color: $dangerColor !important; // sass-lint:disable-line no-important

    .checkboxes__top-label {
      color: $dangerColor !important; // sass-lint:disable-line no-important
    }
  }

  &__top-label {
    @include default-transition;
    @include font-size-s;
    color: $textColorLight !important; // sass-lint:disable-line no-important
    padding-left: $gapSizeXXS;
    padding-right: $gapSizeXXS;
  }
}

.label {
  font-size: map-get($fontSizes, small) !important; // sass-lint:disable-line no-important
}

.input {
  @include mobile-only {
    margin-bottom: $gapSizeL;
  }

  margin-bottom: $gapSizeXL;
}
