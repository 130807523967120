@import 'src/styles/mixins';

.container {
  @include operation-details-popup-container;
}

.loader {
  margin-bottom: $gapSizeS;
  margin-top: $gapSizeXL;
}
