@import 'src/styles/mixins';

.container {
  @include mobile-only {
    background: none;
    grid-template-areas:
    'logo'
    'children'
    'register'
    'copyright';
    grid-template-columns: 100%;
    height: auto;
    padding: $gapSizeL;
  }

  background: linear-gradient(90deg, transparent 50%, $white 50%);
  box-sizing: border-box;
  display: grid;
  grid-template-areas:
  'logo children'
  'hero children'
  'copyright children';
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  height: 100vh;
  padding: $gapSizeXL;

  &:before {
    @include mobile-only {
      width: 100%;
    }

    background-image: url('../../../assets/images/login-page-bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
    z-index: $zIndexNegative;
  }
}

.logo {
  @include mobile-only {
    margin-bottom: $gapSizeL;
    margin-top: 0;
    max-width: 15.6rem;
  }

  grid-area: logo;
  margin-left: auto;
  margin-right: auto;
  margin-top: $gapSizeXL;
}

.hero-image {
  @include mobile-only {
    display: none;
  }

  grid-area: hero;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.children {
  @include mobile-only {
    border-radius: $bigBorderRadius;
  }

  background: $white;
  grid-area: children;
  padding: $boxSidePadding;
}

.copyright {
  @include mobile-only {
    text-align: center;
  }

  @include small-mobile-only {
    text-align: left;
  }

  color: $textColorLight;
  grid-area: copyright;
  margin-top: $gapSizeXL;
  text-align: center;
}
