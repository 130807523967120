@import 'src/styles/mixins';

.container {
  @include desktop-only {
    box-sizing: border-box;
    padding-left: $gapSizeXL;
    padding-right: $gapSizeXL;
    width: $boxWidthSizeXS;
  }

  display: flex;
  flex-direction: column;
  padding-left: $gapSizeM;
  padding-right: $gapSizeM;
}

.image {
  margin-bottom: $gapSizeL;
  margin-top: $gapSizeL;
}

.title {
  @include font-size-xl;
  margin-bottom: $gapSizeS;
}

.description {
  @include font-size-m;
  @include font-light;
  color: $textColorLight;
  margin-bottom: $gapSizeM;
}

.link {
  @include link;
  margin-bottom: $gapSizeXL;
  margin-left: auto;
  margin-right: auto;
  margin-top: $gapSizeL;
}
