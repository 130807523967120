@import 'src/styles/mixins';

.progress {
  align-items: center;
  border-radius: $bigBorderRadius;
  display: flex;
  height: 2rem;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  &--bigger {
    height: 2.4rem;
  }
}

.bar {
  @include mobile-only {
    height: 1.6rem;
  }

  background: $primaryBackgroundColor;
  height: 100%;
  width: 100%;

  &--achieved {
    background: $primaryColor;
    left: 0;
    position: absolute;
    width: 0;
  }
}
